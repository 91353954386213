$block-gap: rem-calc(15);

.block-grid {
    @extend .table-grid;
    display: flex;
    flex-wrap: wrap;
    margin-left: ($block-gap * -1);
    margin-right: ($block-gap * -1);
    width: auto !important;
    .page-component {
        margin-left: $block-gap;
        margin-right: $block-gap;
    }
}