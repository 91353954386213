.message-readers {
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .5;
  cursor: pointer;

  @include on-event() {
    opacity: 1;

  };

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-top: 1px solid #ccc;
  }

  &__reader {
    margin: 0 2px;
  }
}

.readers-details {
  width: 400px;
  @include rwd(phone) {
    width: 300px;
  }

  &__head {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__reader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0;

    .av {
      margin-right: 15px;
    }
  }

  &__name {
    font-weight: bold;
    //margin-bottom: 4px;
  }

  &__date {
    color: $text-muted;
  }
}