.post {
  $post: &;
  border-radius: 4px;
  padding: 14px;
  background-color: #fff;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
  box-shadow: 0 0 15px rgba($theme, .02);

  &__sticky {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 12px;
  }

  &__top {
    @include flex(space-between, center, false);
  }

  &__actions {
    @include flex(flex-end, center, false);
  }

  &__sticky-icon {
    font-size: 16px;
    color: $primary;
    margin: 0 5px;

    #{$icon-selector} {
      margin-top: -3px;
    }
  }

  &__sticky-oldest {
    margin-bottom: 39px;
  }

  &__meta {
    margin: 5px 0;

    &-icon {
      font-size: 14px;
      margin: 0 1px;
      font-weight: 700;
      color: $theme-gray;
      width: 10px;
    }

    &-date {
      flex-shrink: 0;
      margin: 0 1px;
      @include flex(flex-start, center, false, false, true);
      transform: translateY(-1px);

      #{$icon-selector} {
        margin-left: 3px;
        margin-right: 1px;
        flex-shrink: 0;
        font-size: 11px;
        width: 15px;
        transform: translateY(-1px);
      }
    }

    .text {
      * {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .flex-container {
      display: flex;
    }

    @include ellipsis;
    span {
      @include ellipsis;
      display: block;

      &:first-child {
        flex-shrink: 0;
      }
      cursor: default;
      flex-shrink: 1;
      flex-grow: 0;
    }

    i {
      flex-shrink: 0;
    }
  }

  &__title {
    @extend .text--large;
    @extend .text--bold;
    display: block;
    width: 100%;
  }

  &__content {
    white-space: pre-line;
    @extend .text--dark;
    margin-top: 18px;

    @include rwd(small) {
      margin-top: 12px;
    }

    a {
      @extend .text;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    h1, h2, h3 {
      margin-top: 0;
      margin-bottom: 10px;
    }

    h1 {
      @include font(20, 24, 0);
    }

    h2 {
      @include font(18, 20, 0);
    }

    h3 {
      @include font(16, 18, 0);
    }
  }

  &__likes-count {
    position: absolute;
    right: -4px;
    top: -4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $theme;
    color: #fff;
    text-align: center;
    line-height: 16px;
    font-size: 10px;
    font-weight: 500;
  }

  &__like {
    height: 30px;
    padding: 4px 8px;
    border-radius: 99px;
    background-color: $theme-bg;
    color: $theme;
    font-weight: 300;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    @include transition(background-color);

    #{$icon-selector} {
      font-size: 15px;
      @include transition(color);
    }

    &--liked {
      background-color: $theme;
      color: $white;
    }
  }

  &__footer {
    margin-top: 15px;
    @include flex(space-between, center, false);

    #{$post}__left {
      @include flex;
    }

    #{$post}__right {
      @include flex;
    }
  }

  &__comments-info {
    @extend .text--medium;
    @extend .text--gray;
    padding-left: 10px;
    font-weight: 300;

    &:first-child {
      padding-left: 0;
    }
  }

  &__gallery {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-gap: 8px;

    padding-top: 10px;
    margin: -2px -2px 0 -2px;
    position: relative;

    &--single {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
    }

    &--double {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
    }

    &--triple {
      grid-template-columns: 3fr 2fr;
      grid-template-rows: repeat(1, minmax(0, 1fr));

      & div:nth-child(1) {
        grid-row: 1 / span 2;
      }
    }
  }

  &__media-counter {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(30%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $theme;
    @include flex(center, center, false);
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    @include transition(background-color);
    box-shadow: 0 0 20px -7px $theme;

    @include on-event() {
      color: #fff;
      background-color: $theme-hover;
    }
  }

  &__photo {
    position: relative;

    & > a[data-fancybox] {
      padding-top: 66.66%;
      position: relative;
      width: 100%;
      border-radius: 12px;
      overflow: hidden;
      position: relative;

      img, & > div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }

    &__more {
      background: rgba(0, 0, 0, .3);
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      color: white !important;
      transition: all .2s;

      &:hover, &:active {
        background: rgba(0, 0, 0, .4);
      }
    }

    img, a {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    &-no-display {
      display: none;
    }
  }

  &__attachments {
    @include flex();
    margin: 0 -1%;
    padding-top: 10px;

    & > a {
      display: block;
      width: 48%;
      margin: 5px 1%;
      border-radius: 4px;
      background-color: $theme-bg;
      overflow: hidden;

      @include rwd(small) {
        width: 100%;
      }

      @include on-event() {
        #{$post}__attachment-name {
          color: $primary;
        }
      }
    }
  }

  &__attachment {
    @include flex($wrap: false);
    padding: 8px;

    #{$icon-selector} {
      font-size: 18px;
      color: $secondary;
    }

    &-name {
      padding-left: 5px;
      @include ellipsis;
      display: inline-block;
      @include transition(color);
      @extend .text--small;
      @extend .text--gray;
    }

    &--more {
      background-color: $theme-bg;

      #{$icon-selector} {
        color: $primary;
      }

      #{$post}__attachment-name {
        color: $primary;
      }
    }
  }

  &__comments {
    text-align: left;
    padding-top: 10px;

    &-add {
      @include flex($wrap: false);
      position: relative;

      .input-helper {
        flex: 1 0 auto;
        margin-bottom: 0;
        margin-top: 0;
      }

      textarea {
        border-color: #f1f1f1;
        padding: 14px 50px 14px 15px;
        min-height: 46px;
        border-radius: 16px;
        resize: none;
        font-size: rem-calc(13);
        font-weight: 400;
        @include placeholder {
          font-size: rem-calc(13);
          font-weight: 400;
        }
      }

      label {
        top: 23px;
        left: 15px;
        font-size: rem-calc(13);
        font-weight: 400;
      }

      &-btn {
        outline: none;
        padding: 10px;
        position: absolute;
        right: 5px;
        bottom: 11px;
        background-color: transparent;
        box-shadow: none;
        border: none;
        cursor: pointer;

        #{$icon-selector} {
          color: $primary;
        }
      }
    }
  }

  &-comment {
    @include flex($va: flex-start, $wrap: false);
    margin: 15px 0;

    &:first-child {
      border-top: 1px solid $theme-bg;
      padding-top: 10px;
      margin-top: 10px;
    }

    &__left {
      flex: 0 0 25px;

      .avatar {
        &__initials {
          font-size: rem-calc(14);
        }
      }
    }

    &__right {
      margin-left: 5px;
      flex-grow: 1;

      &-bg {
        border-radius: 15px;
        background-color: $theme-bg;
        padding: 5px 15px;
        position: relative;
      }
    }

    &__author {
      @extend .text--small;
      @extend .text--gray;
    }

    &__content {
      @extend .text--small;
      @extend .text--dark;
      margin-top: 1px;
      white-space: pre-line;
      word-break: break-word;
      padding-right: 3px;
    }

    &__date {
      @extend .text--small;
      @extend .text--gray;
      @extend .text--fw-light;
      padding-left: 15px;
      margin-top: 1px;
    }

    &__remove {
      padding: 7px 8px 7px 7px;
      border-radius: 50%;
      text-align: center;
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      right: -5px;
      bottom: -5px;
      z-index: 1;
      background-color: $danger;
      color: #fff;
      @include flex(center, center, false);

      &:before {
        margin: 0;
        margin-left: 1px;
        font-size: 11px;
      }
    }
  }

  .react-loader {
    flex: 1;
  }

  &--event {
    box-shadow: none;
    background: transparent;
    padding: 0;
  }

  &-event {
    @include flex(flex-start, stretch, false);

    &__icon-container {
      @include flex(center, center, false);
    }

    &__icon {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      @include flex(center, center, false);
      z-index: 2;

      #{$icon-selector} {
        font-size: 14px;
        color: #fff;
      }
    }

    &__top {
      @include flex(flex-start, center, false);
      width: 100%;
    }

    &__date {
      background-color: transparent;
      @extend .text--small;
      @extend .text--gray;
      @extend .text--fw-light;
      margin-left: auto;
      padding-left: 10px;
      flex-shrink: 0;
    }

    &__title {
      display: block;
      @extend .text;
      @extend .text--dark;
      @extend .text--fw-medium;
    }

    &__text {
      margin-top: 3px;
      display: block;
      @extend .text--small;
      @extend .text--dark;
      width: 100%;
    }

    &__details {
      flex-grow: 1;
      background-color: #fff;
      border-radius: 4px;
      @include flex(flex-start, center, true);
      padding: 10px 12px;
      margin-left: -5px;
    }

    &__action {
      @include flex(center, center, false);
      z-index: 2;
      margin-left: 5px;
    }
  }
}

.video-thumb {
  position: relative;

  #{$icon-selector} {
    font-size: 64px;
    font-weight: 600;
    color: rgba(#fff, .75);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
