.table-header {
  margin-top: 20px;
  margin-bottom: 20px;

  @include rwd(small) {
    background-color: $theme-bg;
    padding: 10px 0;
    position: relative;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: $theme-bg;
      width: 10px;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }

  &--margin {
    margin-bottom: 15px;

    @include rwd(small) {
      margin-bottom: 10px;
    }
  }

  &__title {
    margin: 0 15px;
    @include font(14, 17, 0);
    color: $secondary;
    text-align: center;

    @include rwd(phone) {
      @include font(12, 15, 0);
    }

    &-helper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 7px;
    }

    span.text {
      display: block;
      font-weight: 400;

      &:first-child {
        font-weight: 400;
        @include font(16, 16, 0);
        margin-bottom: 4px;
        color: $primary;
        @include rwd(phone) {
          @include font(14, 17, 0);
          margin-bottom: 2px;
        }
      }
    }

    .btn {
      display: table;
    }

    label {
      cursor: pointer;
      @include on-event() {
        span.text {
          &:first-child {
            color: $primary;
          }
        }
        input.datepicker {
          //background: $primary;
          //color: #fff;
        }
      }
    }

    [data-siblings-container] {
      width: 100%;
    }

    .datepicker-container {
      margin-bottom: 0;

      span.text {
        @include font(13, 14, 0);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;

        #{$icon-selector} {
          color: $primary;
        }

        @include rwd(small) {
          @include font(11, 12, 0);
          #{$icon-selector} {
            font-size: rem-calc(10);
          }
        }

        &:first-child {
          @include font(13, 14, 0);
          color: $secondary;
          margin-bottom: 0;
          margin-left: -7px;
          transition: .2s;
          margin-top: 6px;
          @include rwd(small) {
            margin-top: 1px;
            @include font(11, 12, 0);
          }
        }
      }
    }

    input.datepicker {
      @include font(13, 17, 0);
      color: $primary;
      text-align: center;
      border: none;
      box-shadow: none;
      transition: .2s;
      height: 22px;
      width: 80px;
      background: transparent;
      border-radius: 0;
      @include rwd(small) {
        margin-top: 1px;
        @include font(11, 12, 0);
        height: 15px;
        width: 70px;
      }
    }
  }

  .btn--prev, .btn--next {
    position: absolute;
    white-space: nowrap;
    top: 50%;
    transform: translateY(-50%);
    @include rwd(phone) {
      @include font(14, 17, 0);
    }

    span {
      display: block;
      font-weight: 400;
      @include font(13, 14, 0);
      @include rwd(phone) {
        @include font(11, 13, 0);
      }

      &:nth-child(2) {
        font-weight: 400;
        @include font(15, 14, 0);
        margin-top: rem-calc(4);
        color: rgba($primary, .7);
        @include rwd(phone) {
          @include font(14, 17, 0);
          margin-top: 0;
        }
      }
    }

    &.btn--transparent {
      span {
        &:first-child {
          transition: .2s;
        }
      }

      @include on-event() {
        span {
          &:first-child {
            color: $primary-light;
          }
        }
      }
    }
  }

  .btn--prev {
    left: 0;
    margin-right: 25px;
    text-align: left;
    @include rwd(small) {
      margin-right: 2px;
      order: 1;
      padding-left: 15px;
      padding-right: 10px;
      &:before {
        margin-top: 0;
        left: 2px;
      }
    }
  }

  .btn--next {
    right: 0;
    margin-left: 25px;
    text-align: right;
    @include rwd(small) {
      margin-left: 2px;
      order: 3;
      padding-right: 15px;
      padding-left: 10px;
      &:after {
        margin-top: 0;
        right: 2px;
      }
    }
  }
}
