.poll {
  $el: &;
  margin-top: 8px;

  &__answer-wrapper {
    border-radius: 5px;
    padding: 10px 12px;
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;
    transition: color 150ms;
    user-select: none;

    &--voted {
      .poll__votes i {
        display: inline;
      }
    }

    &--link {
      display: block;
    }
  }

  &__answer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  &__answer {
    font-weight: 600;
    flex-grow: 1;
  }

  &__votes {
    text-align: right;
    font-weight: 600;
    font-size: 12px;
    background-color: #FFF;
    color: #467BBE;
    padding: 2px 7px;
    border-radius: 999px;
    transition: all 150ms;

    i {
      display: none;
      margin-right: 6px;
      margin-left: 2px;
      font-weight: 500;
    }
  }

  &__votes-indicator {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }
}
