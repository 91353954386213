.quick-menu {
  $el: &;
  display: flex;
  flex-wrap: wrap;
  margin: 20px -5px;
  &__head {
    flex-basis: 100%;
    margin: 0 5px;
  }
  &__item {
    flex-basis: 20%;
    width: 20%;
    padding: 5px;
    @include rwd(medium) {
      flex-basis: 50%;
      width: 50%;
    }
    @include rwd(small) {
      flex-basis: 100%;
      width: 100%;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 15px;
    border-radius: 5px;
    background-color: #fff;
    color: $secondary;
    box-shadow: 0 0 5px rgba(#000, .01);
    transition: all 300ms;
    text-align: left;
    @include rwd(medium) {
      padding: 15px;
    }
    @include rwd(phone) {
      border: 1px solid #f3f3f3;
      padding: 12px 15px;
      color: $text;
    }
    #{$icon-selector} {
      font-size: 20px;
      color: $primary !important;
    }
    @include on-event() {
      box-shadow: 0 2px 6px rgba(#000, .05);
    }
  }
  &__name {
    display: block;
    font-weight: 400;
    font-size: 14px;
    padding-left: 10px;
    @include rwd(large) {
      font-size: 13px;
    }
    @include rwd(medium) {
      margin: 0;
    }
  }
}
