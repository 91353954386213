.hidden {
  display: none;
}

.mb10 {
  margin-bottom: 10px;
}

.error {
  color: $danger;
}

.cke_textarea_inline {
  color: #222;

  img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
  }
}

.form-box {
  .input-helper {
    margin-bottom: 5px;
  }

  .input-description {
    margin-top: 0;
  }
}
