.lab {
  @include flex(center, center, false, false, true);
  text-align: center;
  border-radius: 40px;

  i {
    margin-right: 12px;
    flex-shrink: 0;
  }

  // sizes
  &--tiny {
    padding: 3px 5px;
    border-radius: 4px;
    @extend %text-tiny;
  }

  &--small {
    padding: 5px 12px;
    @extend %text-base;
  }

  // colors
  &--success {
    background-color: rgba($success, .1);
    color: $success;
  }

  &--danger {
    background-color: rgba($danger, .1);
    color: $danger;
  }

  &--info {
    background-color: rgba($info, .1);
    color: $info;
  }

  &--warning {
    background-color: rgba($warning, .1);
    color: $warning;
  }

  &--bg {
    background-color: $theme-bg;
    color: $theme-dark;
  }
}

.labels {
  @include flex(flex-start, center, true);
  margin-left: -6px;
  margin-right: -6px;
  margin-top: -3px;

  .lab {
    margin: 3px 6px;
  }

  &--fixed-width {
    margin-left: -1%;
    margin-right: 1%;

    .lab {
      margin: 3px 1%;
      width: 48%;

      @include rwd(small) {
        width: 98%;
      }
    }
  }
}
