@media print {
  .pull-to-refresh--helper, .main-header, .sidebar, .mobile-actions {
    display: none;
  }
  .main-content {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    .content {
      padding: 10px 5px;
    }
  }
  .table-grid {
    .promoted-column {
      font-size: 13px;
    }
    .row {
      padding: 3px 5px;
      box-shadow: none !important;
    }
  }
  .input-search {
    display: none;
  }
  .inline-calendar {
    padding-bottom: 10px;
    margin-top: 10px;
    &__layout {
      display: none;
    }
  }
  .heading-with-element {
    margin: 0;
    text-align: center;
    justify-content: center;
    .element, .dropdown-helper {
      display: none;
    }
  }
  .msg {
    display: none;
  }
  #tidio-chat {
    display: none;
  }
  .item-with-avatar {
    padding-left: 0;
    &.bigger {
      padding-left: 0;
    }
  }
  .avatar {
    display: none;
  }
  .table-actions {
    display: none;
  }
  .btn {
    display: none;
  }
  .table-header__title-helper {
    padding-left: 0;
  }
}