.support-info {
  font-weight: 300;
}

.support-topic {
  &__title {
    @include flex($wrap:false);
    &-value {
      padding-right: 20px;
      @include rwd(small) {
        span {
          @include font(13, 16, 0);
        }
      }
    }
  }
  .count {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin-right: 10px;
    padding: 0;
    position: relative;
    display: block;
    background-color: $primary;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 1px;
  }
  .value {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 13px;
  }
}

.support-post {
  &__content {
    margin-top: 8px;
  }
}
