.pagination {
  $pagination: &;
  @include flex(flex-start, stretch, false);
  margin-left: -3px;
  margin-right: -3px;
  margin-top: 20px;
  @include rwd(phone) {
    justify-content: center;
  }
  &__item {
    margin: 0 3px;
    @include rwd(small) {
      margin: 0 2px;
    }
    &--current {
      #{$pagination}__link {
        border-color: $primary;
        cursor: default;
        background-color: $primary;
        color: #fff;
        @include on-event() {
          background-color: $primary;
          color: #fff;
        }
      }
    }
    &--prev, &--next {
      //@include rwd(small) {
      //  display: none;s
      //}
      #{$pagination}__link {
        padding: 10px 11px;
        #{$icon-selector} {
          @include font(16, 14, 0);
        }
      }
    }
  }
  &__link {
    padding: 10px 14px;
    border: 1px solid #e6e6e6;
    @include flex(center, center, false);
    color: $secondary;
    @include transition(background-color .1s);
    border-radius: 2px;
    @include font(13, 13, 0);
    box-shadow: 0 0 5px 1px rgba(0,0,0,.02);
    background-color: #fff;
    @include on-event() {
      background-color: #f6f6f6;
      color: $secondary;
    }

    @include rwd(small) {
      padding: 10px;
    }
  }
}