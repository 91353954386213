.badge {
  &-icon {
    border-radius: 50%;
    position: relative;

    &--default {
      width: 32px;
      height: 32px;
      background-color: $primary;
      color: $white;
    }

    #{$icon-selector} {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }
  }
}

.badges {
  @include flex(flex-start, center, true);
  margin-left: -2px;
  margin-right: -2px;
  flex-shrink: 0;
  flex-grow: 1;

  & > div {
    margin-left: 2px;
    margin-right: 2px;
  }
}