.latest-list {
  margin-top: 10px;
  &__single {
    display: block;
    cursor: pointer;
    transition: .2s;
    color: $text;
    border-bottom: 1px solid #f3f3f3;
    padding: 8px 20px;
    margin: 0 -20px;
    @include rwd(phone) {
      margin: 0;
      padding: 8px 0;
    }
    &:hover {
      background-color: #fafafa;
    }
  }
  &__name {
    font-weight: 600;
    @include font(14, 16, 0);
    margin-bottom: 5px;
    color: $primary;
    span {
      color: $secondary;
      @include font(13, 16, 0);
    }
  }
  &__info {
    color: $secondary;
    @include font(13, 16, 0);
    strong {
      color: $text;
      font-weight: 500;
    }
  }
  &__button {
    text-align: center;
    padding-top: 15px;
  }
}