.diary {
  $block: &;

  &__single {
    display: flex;
    margin-bottom: 10px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    padding: 8px;

    @include rwd(phone) {
      flex-wrap: wrap;
      padding: 10px 0;
      border-left: none;
      border-right: none;
      border-top: 0;
      border-radius: 0;
    }

    &--padding {
      padding: 10px 15px;
    }
  }
  &__top {
    @include flex(flex-start, center, false);
    @include rwd(phone) {
      display: block;
    }
  }
  &__date {
    flex-shrink: 0;
    padding-right: 15px;
    color: $gray-600;
    width: 150px;
    text-align: right;
    border-right: 1px solid #dfe5ff;
    margin-right: 15px;
    @include font(14, 16, 0);

    @include rwd(phone) {
      @include flex(flex-start, center, false);
      border-right: none;
      width: 100%;
      margin-bottom: 4px;
      color: $text;
    }

    @include rwd(small) {
      @include font(13, 15, 0);
    }
    span {
      display: block;
      color: $text;
      @include rwd(phone) {
        display: inline-block;
        &:after {
          content: ',';
          margin-right: 5px;
        }
      }
    }
  }
  &__date-calendar {
    @include flex(flex-start, center, false);
    font-weight: 500;
    color: #111;
    #{$icon-selector} {
      margin-right: 5px;
      color: $primary;
    }
  }
  &__subject {
    @extend %text-large;
    font-weight: 600;
    color: $primary;
  }
  &__description {
    @include font(16, 18, 0);
    background-color: $theme-light;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;

    @include rwd(small) {
      @include font(15, 18, 0);
    }
  }
  &__day-works {
    margin: 5px 0;
  }
  &__day-work {
    display: flex;
    margin-top: 20px;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #ecedfc;
    padding-bottom: 20px;

    &:first-child {
      margin-top: 5px;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__day-work-text {
    flex: 1;
  }
  &__day-work-signed{
    margin-top: 10px;
    flex-basis: 100%;
    text-align: right;
  }

  &__day-subject {
    @include rwd(small) {
      background-color: #edf1ff;
      padding: 7px;
      margin-top: -10px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
    &-heading {
      @include rwd(small) {
        display: block;
        @include font(13, 15, 0);
        color: #75a0ff;
        font-weight: 400;
      }
    }
  }

  .weekly-activities {
    table {
      table-layout: fixed;
      width: 100%;
      text-align: center;
      th, td {
        padding: 13px 5px;
        @include font(14, 16, 0);

        @include rwd(large) {
          @include font(12, 14, 0);
        }
        @include rwd(medium) {
          padding: 0;
          @include font(13, 15, 0);
        }
      }
      tr {
        &:nth-child(odd) {
          td {
            background-color: $gray-200;
          }
        }
        &:nth-child(even) {
          td {
            background-color: $gray-100;
          }
        }

        @include rwd(medium) {
          display: block;
          margin-top: 20px;

          &:first-child, &:nth-child(2) {
            margin-top: 0;
          }
        }
        th {
          background-color: $primary;
          color: #fff;
          &:first-child {
            background-color: $theme-light;
            color: $primary;
          }

          @include rwd(medium) {
            display: none;
          }
        }
        td {
          &:first-child {
            font-weight: 700;
            @include rwd(medium) {
              &:before {
                display: none;
              }
              span {
                width: 100%;
                padding: 10px;
                background-color: $primary;
                color: #fff;
                text-align: center;
              }
            }
          }

          @include rwd(medium) {
            @include flex(flex-start, center, false);
            text-align: left;

            &:nth-child(even) {
              background-color: $gray-100;
            }
            &:nth-child(odd) {
              background-color: $gray-200;
            }

            &:before {
              content: attr(data-label) ':';
              width: 50%;
              padding: 8px 20px;
              display: block;
              font-weight: 700;
              text-align: right;
            }
            span {
              display: block;
              width: 50%;
            }
          }
        }
      }
    }
    &__single {
      border: 1px solid $gray-200;
      border-radius: 5px;
      margin-bottom: 20px;

      &-name {
        background-color: $gray-200;
        @include font(16, 20, 0);
        font-weight: 600;
        color: $primary;
        padding: 2px 30px;
        border-radius: 2px;
      }


      &-details {
        padding: 15px 10px;
      }
    }

    // form
    &-form-item {
      padding: 30px 5px 20px;
      border-bottom: 2px solid $primary;

      @include rwd(small) {
        padding: 10px 0;
      }

      &:nth-child(even) {
        background-color: #f8f9fd;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 15px;
      }

    }
  }
}

.diary-entry {
  counter-reset: element;

  &__single {
    position: relative;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid $gray-200;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__details {
    @include flex(flex-start, center);
    color: $gray-700;
  }

  &__detail {
    margin: 2px 5px 2px 0;
  }

  &__description {
    margin-bottom: 10px;
  }

  &__actions {

  }
}

.day-schedule {
  table {
    width: 100%;
    tr {
      &:nth-child(even) {
        td {
          background-color: $theme-light;
        }
      }
    }
    td {
      padding: 10px 20px;

      @include rwd(small) {
        padding: 7px 10px;
      }

      &:nth-child(1) {
        width: 25%;
        text-align: right;
        @include rwd(phone) {
          width: 40%;
        }
        @include rwd(small) {
          width: 45%;
        }
      }
      &:nth-child(2) {
        width: 75%;
        @include rwd(phone) {
          width: 60%;
        }
        @include rwd(small) {
          width: 55%;
        }
      }
    }
  }
}
