.dropdown-list {
  position: absolute;
  z-index: 9;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  padding: 5px 5px;
  width: 200px;
  max-height: 200px;
  overflow: auto;

  &::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ECF1F5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
  }

  &__item {
    padding: 2px 0;
  }
}