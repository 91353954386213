//row
$row-margin: 10px;
$row-padding-top-bottom: 14px;
$row-padding-left-right: 20px;
$row-padding: $row-padding-top-bottom $row-padding-left-right;
$row-bg-color: $white;

.table-grid {
  //width: 100%;
  color: $gray-600;
  @include font(13, 17, 0);
  margin-bottom: 0;
  margin-left: -10px;
  margin-right: -10px;
  @include rwd(phone) {
    margin-left: -5px;
    margin-right: -5px;
  }

  &__responsive {
    overflow-x: scroll;

    .row {
      @include rwd(phone) {
        min-width: 640px;
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    //width: 100%;
    margin: $row-margin;
    padding: $row-padding;
    background-color: $row-bg-color;
    border: 2px solid $row-bg-color;
    border-radius: 10px;
    transition: .15s linear;
    box-shadow: 0 0 5px rgba(0, 0, 0, .01);
    @include font(13, 16, 0);
    color: $secondary;

    &--no-padding {
      padding: 0;
      justify-content: space-between;
    }

    &--transparent {
      background-color: transparent;
      border-color: transparent;
      .page-box{
          width: 33%;
          @include rwd(large) {
            width: 100%;
          }
      }
    }

    &--flex-stretch{
      align-items: stretch;
    }

    @for $i from 1 through 12 {
      .w#{$i} {
        padding: 0 5px;
      }
    }

    a {
      @include font(13, 16, 0);
    }

    @include rwd(phone) {
      padding: 10px 3px;
    }

    &.active {
      border-color: #90b7ff;
      background-color: $light-primary;
    }

    &.head {
      background: none;
      border-color: transparent;
      padding-bottom: calc(#{$row-padding-top-bottom} / 2);
      font-weight: 600;
      color: $gray-500;
      text-transform: uppercase;
      cursor: default !important;
      @include font(11, 11, 0);
      @include on-event() {
        box-shadow: none;
      }

      @include rwd(phone) {
        padding-top: 0;
      }
    }

    &.summary {
      background: none;
      border-color: transparent;
      font-weight: 700;
      color: $primary;
      text-transform: uppercase;
      cursor: default !important;
      @include font(13, 13, 0);

      span {
        color: $secondary;
      }

      @include on-event() {
        box-shadow: none;
      }

      &__date {
        display: block;
        text-align: right;
        font-size: 11px;
        text-transform: none;
        font-weight: 400;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: -10px;
        margin-bottom: 40px;
        color: $secondary;
      }
    }

    .label {
      //margin-top: -4px;
      //margin-bottom: -4px;
      /*&:first-of-type {
          margin-left: -10px;
      }*/
    }

    &__info {
      @include font(11, 13, 0);
    }
  }

  .promoted-column {
    color: $text;
    font-weight: 600;
    @include font(14, 16, 0);
    @include rwd(large) {
      @include font(13, 15, 0);
    }

    a {
      color: $primary;
      @include font(14, 16, 0);
      font-weight: 500;
      @include on-event() {
        color: $primary-hover;
      }
      @include rwd(large) {
        @include font(15, 17, 0);
      }
    }
  }

  .lp {
    color: #868e96;
    @include font(13, 17, 0);
    display: inline-block;
    width: 16px;
    cursor: default;
  }

  .number {
    text-align: right;
  }

  .table-actions {
    @include flex(flex-end, center);
    //font-size: 0;
    min-height: 43px;
    text-align: right;

    .is-open {
      [data-toggle="dropdown"] {
        .btn {
          background: transparent;
        }

        #{$icon-selector} {
          color: $primary;
        }
      }
    }

    [data-toggle="dropdown"] {
      display: inline-flex;
      padding: 7px 0;
      @include rwd(phone) {
        padding: 0;
        transform: translateY(10px);
      }
    }

    .dropdown-content {
      text-align: left;
    }

    .btn {
      display: inline-block;
      text-align: center;
      padding: 5px;
      //margin: -5px auto -5px auto;
      @include rwd(phone) {
        margin: 0;
        padding: 0;
      }
      @include on-event() {
        background: transparent;
        color: $text;
      }
    }

    .fa-ellipsis-h {
      font-size: 18px !important;
    }
  }

  &.md--smaller {
    .row {
      @include rwd(medium) {
        @include font(11, 16, 0);
        a {
          @include font(11, 16, 0);
        }
      }
    }

    .promoted-column {
      @include rwd(medium) {
        @include font(12, 16, 0);
        a {
          @include font(12, 16, 0);
        }
      }
    }
  }

  [data-row-url] {
    cursor: pointer;
  }

  &.table-gap {
    .row {
      & > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  &.gap-bottom{
    margin-bottom: 50px;
  }
}

.table-row-link {
  .row {
    cursor: pointer;
    @include on-event() {
      box-shadow: 0 0 10px rgba(0, 0, 0, .06);
    }
  }

  .promoted-column {
    color: $primary;
  }
}

.table-numbered {
  counter-reset: table;

  .row {
    @include rwd(phone, true) {
      padding-left: 35px !important;
    }
  }

  .row:not(.head):not(.summary) {
    position: relative;
    @include rwd(phone, true) {
      &:before {
        counter-increment: table;
        content: counter(table) ".";
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        font-size: 14px;
        color: #e4e4e4;
        font-weight: 500;
        z-index: 1;
      }
    }
    @include rwd(phone) {
      &:before {
        display: none;
      }
    }
    @include rwd(print) {
      padding-left: 13px;
      &:before {
        counter-increment: table;
        content: counter(table) ".";
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        font-size: 14px;
        color: #e4e4e4;
        font-weight: 500;
        z-index: 1;
        margin-right: 10px;
      }
    }
  }
}

.table-full-width {
  .row {
    border-radius: 0;
    border: none;
    margin-left: -10px;
    margin-right: -10px;
    padding: 0;
  }
}

.table-row-no-padding {
  .row {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fancybox-content {
  .table-grid {
    .row {
      padding-left: 0;
      margin: 5px 10px;
    }
  }
}
