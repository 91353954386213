.dashboard-summary {
  $block: &;
  display: flex;
  flex-wrap: wrap;
  margin: 20px -5px;

  &--no-margin {
    margin: 0;
  }

  &__head {
    flex-basis: 100%;
    margin: 0 5px 5px;
  }

  &__item {
    flex-basis: 25%;
    padding: 5px;
    @include rwd(medium) {
      flex-basis: 50%;
      &:nth-child(3), &:nth-child(4) {
        order: 4;
      }
    }
    &__20 {
      flex-basis: 20%;
      @include rwd(medium) {
        flex-basis: 50%;
        &:nth-child(3), &:nth-child(4) {
          order: 4;
        }
      }
    }

    &--button {
      text-align: center;

      [data-toggle] {
        height: 100%;
      }

      @include rwd(medium) {
        order: 5;
        flex-basis: 100% !important;
        margin: 6px 0;
      }

      #{$block}__content {
        background-color: $success;
        width: 100%;
        color: $white;
        padding-top: 3px;
        padding-bottom: 3px;
        @include flex(center, center, false);

        @include rwd(medium) {
          padding-top: 13px;
          padding-bottom: 13px;
        }

        @include rwd(small) {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }
      }

      #{$block}__title {
        color: $white;
      }

      #{$block}__number {
        font-size: 25px;

        @include rwd(small) {
          font-size: 18px;
        }
      }

      .dropdown-content {
        text-align: left;

        @include rwd(small) {
          left: 5px !important;
          right: 5px !important;
          width: auto !important;
        }
      }
    }
  }

  &__content {
    display: block;
    color: $text;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0 5px rgba(#000, .01);
    padding: 15px 20px;
    text-align: center;
    height: 100%;
    position: relative;
    @at-root #{selector-unify(&, 'a')} {
      &:hover {
        color: $text;
        box-shadow: 0 0 15px rgba(#000, .1);
      }
    }
    @include rwd(phone) {
      border: 1px solid #f3f3f3;
      padding: 15px 10px;
    }
    @include rwd(small) {
      padding: 10px 10px;
    }

    &--soon {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: rgba(#fff, .8);
      }

      &:before {
        content: 'Już wkrótce!';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(30deg);
        font-weight: bold;
        font-size: 16px;
        z-index: 5;
        color: $secondary;
      }
    }
  }

  &__icon {
    font-size: 20px;
    color: $primary;
    margin-bottom: 5px;
  }

  &__number {
    font-size: 36px;
    font-weight: bold;
    line-height: 38px;

    &--smaller {
      font-size: 18px;
      line-height: 18px;
      margin-top: 2px;
    }
  }

  &__title {
    font-size: 12px;
    color: $text;
  }

  &__change {
    font-weight: bold;
    margin-top: 10px;
    font-size: 12px;
    color: $danger;

    span {
      display: inline-block;
      position: relative;
      padding: 0 14px;

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: $danger transparent transparent transparent;
      }
    }

    &--plus {
      color: $success;

      span {
        &:before {
          border-width: 0 4px 4px 4px;
          border-color: transparent transparent $success transparent;
        }
      }
    }
  }

  // modifiers
  &--3-items-mobile {
    @include rwd(small) {
      margin-left: -2px;
      margin-right: -2px;
    }

    #{$block}__item {
      @include rwd(medium) {
        flex-basis: 33.33%;
      }
      @include rwd(small) {
        padding: 2px;
      }
    }

    #{$block}__content {
      @include rwd(small) {
        padding: 5px;
      }
    }

    #{$block}__title {
      @include rwd(small) {
        font-size: 10px;
      }
    }
  }

  &--2-items-mobile {
    @include rwd(small) {
      margin-left: -2px;
      margin-right: -2px;
    }

    #{$block}__item {
      @include rwd(medium) {
        flex-basis: 50%;
      }
      @include rwd(small) {
        padding: 2px;
      }
    }

    #{$block}__content {
      @include rwd(small) {
        padding: 5px;
      }
    }

    #{$block}__title {
      @include rwd(small) {
        font-size: 10px;
      }
    }
  }

}

.diet-today {
  margin: 20px -5px;
  display: flex;
  flex-wrap: wrap;

  &__head {
    flex-basis: 100%;
    margin: 0 5px 5px;

    span {
      font-weight: 500;
      color: $text-muted;
    }
  }

  &__item {
    padding: 5px;
    flex-basis: 33.333333%;
    @include rwd(medium) {
      flex-basis: 50%;
    }
    @include rwd(phone) {
      flex-basis: 100%;
    }
  }

  &__content {
    background: #fff;
    box-shadow: 0 0 5px rgba(#000, .01);
    padding: 15px 20px;
    height: 100%;
    position: relative;
    border-radius: 5px;
    @include rwd(small) {
      padding: 15px 10px;
    }
    @include rwd(phone) {
      border: 1px solid #f3f3f3;
    }
  }

  &__name {
    font-size: 22px;
    font-weight: bold;
    padding-right: 50px;
    @include rwd(tablet) {
      font-size: 18px;
    }
  }

  &__number {
    font-size: 22px;
    border-radius: 50%;
    background: $primary;
    color: #fff;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: 15px;
    right: 20px;
    @include rwd(small) {
      right: 10px;
    }
    @include rwd(medium) {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 17px;
    }
  }

  &__variants {
    flex-grow: 1;
    flex-basis: 100%;
    margin: 0 -4px;
  }

  &__variantItem {
    display: inline-block;
    margin: 0 4px;
    color: $secondary;

    strong {
      color: $primary;
    }
  }

  &__button {
    display: flex;
    flex-basis: 100%;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }

  &__info {
    padding: 0 20px;
    color: $text-muted;
  }
}
