.meeting {
  position: relative;
  background: #333;
  min-height: 100vh;

  &__head {
    display: flex;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    box-shadow: 0 0 10px #000;

    @include rwd(medium) {
      padding: 10px 20px;
      box-shadow: 0 0 5px #000;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: bold;

    @include rwd(medium) {
      display: none;
    }
  }

  &__controls {
    display: flex;

    @include rwd(medium) {
      width: 100%;
      justify-content: center;
    }
  }

  &__button {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-size: 18px;
    background-color: $danger;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;

    @include rwd(medium) {
      width: 44px;
      height: 44px;
      font-size: 16px;
    }

    &:focus, &:active {
      background-color: $danger !important;
      box-shadow: 0 0 5px rgba(0, 0, 0, .1) !important;
    }

    &:hover {
      background-color: lighten($danger, 10) !important;
    }

    &--small {
      width: 28px;
      height: 28px;
      margin-left: 0;
      font-size: 11px;

      svg {
        margin-left: 0;
      }
    }

    .active {
      color: $success;
      display: none;
    }

    .inactive {
      color: #fff;
      display: block;
    }

    &.active {
      background: #fff;

      &:focus, &:active {
        background: #fff !important;
      }

      &:hover {
        background-color: #eee !important;
      }

      .active {
        display: block;
      }

      .inactive {
        display: none;
      }
    }

    &.screen-share {
      margin-left: 40px;

      @include rwd(medium) {
        display: none;
      }

      &, &:focus, &:active {
        color: #666;
        background: #fff !important;
      }

      &:hover {
        background-color: #eee !important;
        color: #666;
      }

      &.active {
        &, &:focus, &:active, &:hover {
          background: $success !important;
          color: #fff;
        }
      }
    }

    &.default {
      &, &:focus, &:active {
        color: #666;
        background: #fff !important;
      }

      &:hover {
        background-color: #eee !important;
        color: #666;
      }

      &.active {
        &, &:focus, &:active, &:hover {
          background: $success !important;
          color: #fff;
        }
      }
    }

    &.exit {
      background-color: #ccc;
      color: #444;
      margin-left: 40px;

      &:hover, &:focus {
        background-color: $danger;
        color: #fff;
      }
    }
  }

  &__area {
    display: flex;

    @include rwd(medium) {
      flex-direction: column;
    }
  }

  &__list {
    background: #eee;
    width: 300px;
    padding: 20px;

    @include rwd(medium) {
      width: 100%;
      order: 2;
      padding: 15px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  &__list-head {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 0;
    padding: 0;


    &--box {
      @include flex(space-between, center);
      margin: 0 0 18px 0;
    }
  }

  &__user {
    @include flex(flex-start, center);
    position: relative;
    padding: 0 36px 0 22px;
    margin-bottom: 14px;
    min-height: 28px;

    @include rwd(medium) {
      margin-bottom: 7px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      width: 14px;
      height: 14px;
      border-radius: 14px;
      background-color: $success;
    }

    .warning {
      &:before {
        background: $warning;
      }
    }

    .danger {
      &:before {
        background: $danger;
      }
    }

    .meeting__button, .mic-icon {
      position: absolute;
      top: 0;
      right: 0;
      min-height: 28px;
    }

    .mic-icon {
      @include flex(center, center);
      min-width: 14px;
      font-size: 11px;
    }

    .mic-active {
      color: $success;
    }

    .fa-microphone-slash {
      color: $danger;
    }
  }

  &__video-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: calc(100vh - 84px);

    @include rwd(medium) {
      min-height: 150px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__video-wrapper {
    width: 25%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    background: #222;
    padding-top: 14.0%;

    @include rwd(medium) {
      width: 50%;
      padding-top: 28.4%;
    }

    &--active {
      order: -1;
      width: 60%;
      padding-top: 33.8%;
      margin-left: 10%;
      margin-right: 10%;

      @include rwd(tablet) {
        width: 90%;
        padding-top: 50.6%;
        margin: 0;
      }

      @include rwd(medium) {
        width: 100%;
        padding-top: 56.4%;
      }
    }
  }

  &__video-name {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 0 10px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-shadow: 0 0 2px #000;

    @include rwd(medium) {
      padding-left: 5px;
      padding-bottom: 5px;
      font-size: 13px;
    }
  }

  &__video-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #eee;
    max-width: 500px;
    padding: 30px;
    border-radius: 10px;
    font-size: 15px;
    width: 100%;

    .fa-spinner {
      font-size: 30px;
      margin-bottom: 15px;
    }

    span {
      font-size: 14px;
    }

  }

}
