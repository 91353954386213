.error-page {
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    left: vw-calc(-250);
    bottom: vw-calc(-250);
    width: vw-calc(950);
    height: vw-calc(600);
    background-color: $primary;
    opacity: .1;
    border-radius: 50%;
    overflow: hidden;
    z-index: 0;

    @include rwd(medium) {
      width: 450px;
      height: 450px;
    }

    @include rwd(small) {
      width: 300px;
      height: 300px;
    }
  }

  &__content {
    color: $text;
    text-align: center;
    padding: 30px 15px;
    max-width: 560px;
    width: 100%;

    a {
      @include transition(color);
      font-weight: 700;
      color: $primary;
      @include on-event() {
        color: $primary-hover;
      }
    }
  }

  &__form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    margin: 40px 0;
    width: 100%;
    position: relative;
    z-index: 10;
    box-shadow: 0 0 8px rgba(0,0,0,.08);

    textarea {
      min-height: 90px;
    }
  }

  &__text {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    text-align: left;
  }

  &__code {
    font-size: rem-calc(150);
    font-weight: 500;
    line-height: 1;
    margin: 50px 0;
    @include rwd(tablet) {
      font-size: rem-calc(100);
    }
    @include rwd(small) {
      font-size: rem-calc(60);
      margin-bottom: 20px;
    }
  }

  &__text-top {
    @include font(30, 36, 0);
    font-weight: 300;
    margin-top: 40px;
    @include rwd(small) {
      @include font(24, 32, 0);
    }
  }

  &__text-middle {
    @include font(22, 26, 0);
    font-weight: 300;
    margin-top: 5px;

    @include rwd(small) {
      @include font(16, 20, 0);
    }

    a {
      font-weight: 300;
      @include font(22, 26, 0);

      @include rwd(small) {
        @include font(16, 20, 0);
      }
    }
  }

  &__text-bottom {
    @include font(16, 22, 0);
    font-weight: 200;
    margin-top: 40px;
  }

  .app-brand {
    a {
      display: inline-block;
    }

    img {
      max-width: 90px;
      min-width: 90px;
      height: 30px;
    }
  }
}
