.login-page {
  font-family: $font-family-redesign;
  @include flex(center, center, false);
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &--mobile {
    background-color: #fff;

    &:before {
      display: none;
    }
  }

  &__layout {
    width: 450px;
    padding: 30px 0;
    //margin-left: auto;
    //margin-right: auto;
    position: relative;
    z-index: 1;

    @include rwd(large) {
      padding: 30px 20px;
      width: 500px;
    }

    @include rwd(phone) {
      width: 100%;
    }

    &--recruitment {
      width: 75%;
      @media screen and (min-width: 1660px) {
        width: 40%;
      }
      @include rwd(medium) {
        width: 100%;
      }

      .hello-box {
        display: flex;
        flex-direction: column;

        img {
          max-width: 50%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .login-form {
    margin-top: 16px;
    background-color: #fff;
    padding: 50px 40px;
    border-radius: 6px;
    box-shadow: 0 0 5px 10px rgba(#E5E9EB, .2);

    &--mobile {
      padding: 0 !important;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;

      .buttons {
        margin: 10px 0 0 0;
        width: 100%;

        .button {
          display: block;
          width: 100%;
          margin: 10px 0;
        }
      }
    }

    @include rwd(medium) {
      padding: 30px 20px;
    }

    &--recruitment {
      padding: 15px 20px;

      #application {
        .error {
          display: flex;
          flex-direction: column;
        }
      }

      .checkbox {
        span {
          &:before {
            top: 4px;
          }

          &:after {
            top: 7px;
          }
        }
      }

      input[type=checkbox] {
        cursor: pointer;
      }

      .input-helper > div
      {
        padding-top: 0;

        & + label {
          position: static;
          color: $primary;
          font-size: 15px;
          font-weight: 700;
        }
      }

      .checkbox-visible-form {
        > label {
          position: static;
          color: $primary;
          font-size: 15px;
          font-weight: 700;
        }
      }

      .form-collection {
        .form-collection__item__remove {
          .btn--remove {
            &:before {
              padding-bottom: 4px !important;
            }
          }
        }

        .form-grid {
          &__label {
            padding-left: 16px;
            font-size: 15px;
            font-weight: 700;
            color: $primary;
            margin-bottom: 10px;
            text-transform: unset;
          }

          &__item {
            .input-helper {
              display: flex;
              flex-direction: column-reverse;
            }
          }
        }
      }
    }
  }

  .forms-recruitment {
    display: flex;
    flex-direction: column-reverse;

    &__full {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include rwd(phone) {
        display: block;
      }
    }
    &__triple {
      width: 32%;

      @include rwd(phone) {
        width: 100%;
      }
      .input-helper {
        width: 100% !important;
      }
    }

    @include rwd(phone, true) {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .input-helper {
      margin-top: 5px;
      margin-bottom: 5px;
      @include rwd(phone, true) {
        width: 49%;
      }
      @include rwd(phone) {
        width: 100%;
      }
    }
  }

  .forms-recruitment-agreements {
    .input-helper {
      width: 100%;
    }

    #application_information {
      .input-helper {
        display: flex;
        flex-direction: column-reverse;

        label {
          color: $text;
          font-weight: 600;

          span {
            &:before {
              top: 5px;
            }
            &:after {
              top: 8px;
            }
          }
        }
      }
    }
  }

  .forms-recruitment-attributes{
    margin-top: 30px;
    > label{
      position: static;
      color: #5889C7;
      font-size: 15px;
      font-weight: 700;
    }
    .input-helper{
      margin-bottom: 8px;
    }
  }

  #forms-recruitment, #application_registered_address, #application_address {
    @include rwd(phone, true) {
      @include flex(space-between, flex-start);
      width: 100%;
    }
  }

  .login-apps {
    text-align: center;
    margin-top: 43px;

    @include rwd(phone) {
      margin-top: 30px;
    }

    .mobile-apps {
      @include flex(center, center, false);
      margin-left: -5px;
      margin-right: -5px;

      .mobile-badge {
        margin: 0 5px;
        @include transition(opacity);
        height: 35px;
        width: auto;

        img {
          width: auto;
          height: auto;
        }

        @include on-event() {
          opacity: .8;
        }
      }
    }
  }

  .login-parent {
    background-color: $theme-light;
    padding: 20px 24px;
    border-radius: 6px;
    margin-top: 40px;
    @include flex(flex-start, center, false);

    @include rwd(phone) {
      margin-top: 30px;
      display: block;
      text-align: center;
    }

    .button {
      flex-shrink: 0;
      margin-left: 25px;

      @include rwd(phone) {
        margin-top: 15px;
      }
    }

    &--mobile {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      flex-wrap: wrap;
      text-align: center;

      .button {
        width: 100%;
        margin-left: 0;
        background-color: $theme-gray;
        margin-top: 15px;
      }
    }
  }

  .login-copyright {
    margin-top: 40px;

    @include rwd(phone) {
      margin-top: 30px;
    }
  }

}

.hidden {
  display: none;
}

.pwd-reqs {
  &__req {
    @include transition(color);
    margin: 1px 0;
    font-weight: 500;
    color: $gray-600;

    #{$icon-selector} {
      @include transition(color);
      margin-right: 5px;
      color: $gray-600;
    }

    &--pass {
      color: $success;

      #{$icon-selector} {
        color: $success;
      }
    }
  }

  &__list {
    margin-top: 5px;
    list-style: none;
    padding-left: 0;
  }
}
