.list {
  min-width: 100%;
  overflow: auto;
  margin-bottom: 14px;

  &::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ECF1F5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
  }

  &--paging {
    margin-bottom: 0;

    &::-webkit-scrollbar, &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
    }
  }
}

.list-row {
  @include flex(flex-start, stretch, false, false, true);
  position: relative;
  min-width: 100%;
  min-height: 60px;
  background: $white;
  margin: 1px 0;
  padding-right: 78px;
  cursor: pointer;

  &:hover:not(.list-row--headers) {
    background: $theme-light;
    transition: background-color .1s linear;
  }

  &:hover .list-cell--person:not(.list-cell--person-header) {
    background: $theme-light;
    transition: background-color .1s linear;

    .list-sticked & {
      &:before {
        box-shadow: 15px 0 15px 0 rgba(226,236,252, 0.8);
        transition: all .1s linear;
      }
    }
  }

  &--no-action {
    padding-right: 0;
  }

  &__action {
    @include flex(center, center);
    position: absolute;
    height: 100%;
    right: 25px;

    button {
      font-size: 16px;
    }
  }

  &--headers {
    min-height: 30px;
    background: transparent;
    cursor: default;
  }
}

.list-cell {
  @include flex(flex-start, center, false);
  width: 200px;
  padding: 5px 10px 5px 15px;
  margin-left: 10px;

  @include rwd(medium) {
    width: 150px;
  }

  &--lp {
    width: 50px;
    padding-left: 25px;
    padding-right: 0;
    margin-right: 15px;

    @include rwd(medium) {
      width: 30px;
      padding-left: 10px;
      margin-right: 20px;
    }

    @include rwd(phone) {
      width: 20px;
    }
  }

  &--person {
    width: 240px;
    padding-left: 10px;
    margin: 0;
    background: $white;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @include rwd(tablet) {
      width: 200px;
    }

    @include rwd(medium) {
      width: 150px;
    }

    @include rwd(phone) {
      width: 130px;
    }

    .list-row--headers & {
      background: $theme-bg;
    }

    .list-sticked & {
      width: 180px;
      margin-right: 60px;

      &:before {
        box-shadow: 15px 0 15px 0 rgba(244,248,251,0.8);
      }

      @include rwd(tablet) {
        width: 160px;
        margin-right: 40px;
      }

      @include rwd(medium) {
        width: 130px;
        margin-right: 20px;
      }

      @include rwd(phone) {
        width: 110px;
        margin-right: 20px;
      }

      .av__layout {
        display: none;
      }

      .av__details {
        padding-left: 0;
      }
    }
  }

  &--sticky {
    position: sticky;
    left: 0;
    z-index: 5;
  }
}