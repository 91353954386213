.label {
  $this: &;
  display: inline-block;
  border-radius: 20px;
  background-color: $primary;
  padding: 6px 12px;
  text-align: left;
  color: $white;
  font-weight: 400;
  margin: rem-calc(1) rem-calc(1);
  @extend %text-base;
  @include rwd(small) {
      @include font(11, 14, 0);
      padding: 4px 10px;
  }
  &--no-padding-left {
      padding-left: 0;
  }
  &--transparent {
      background: transparent;
      color: $secondary;
      &:not(#{$this}--no-hover) {
          @include on-event() {
              color: $secondary;
              background: $gray-200;
          }
      }
  }
  &--red {
      background-color: $danger;
  }
  &--yellow {
      background-color: $warning;
  }
  &--green {
    background-color: $success;
  }
  &--less-radius {
    border-radius: 3px;
  }
  &--bold {
    font-weight: 700;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--small {
    padding: 4px 6px;
    font-size: 10px !important;
    line-height: 12px !important;
  }
}
