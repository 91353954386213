@keyframes rotate {
  0% {
    transform: rotate(0deg);
    width: 0;
  }
  100% {
    transform: rotate(420deg);
    width: 60px;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
    width: 40px;
    height: 40px;
  }
  100% {
    transform: rotate(360deg);
    width: 40px;
    height: 40px;
  }
}

@keyframes loading-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(.3);
    opacity: .3;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes progress {
  0% {
    width: 0;
    stroke-dasharray: 0 100;
  }
}

@keyframes page-load-leave {
  0% {
    width: 0;
  }

  60% {
    width: 65%;
  }

  100% {
    width: 70%;
  }
}

@keyframes page-load-entry {
  from {
    width: 70%;
  }

  to {
    width: 100%;
  }
}
