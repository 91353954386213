.switch {
  display: inline-flex;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  position: relative;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, .06);
  z-index: 0;
  span {
    display: block;
    text-align: center;
    overflow: hidden;
    color: #d8d8d8;
    font-weight: 700;
    font-size: rem-calc(12);
    position: relative;
    z-index: 3;
    width: 34px;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    &:first-child {
      &:after {
        content: 'N';
      }
    }
    &:nth-child(2) {
      width: 24px;
    }
    &:last-child {
      &:after {
        content: 'T';
      }
    }
  }
  &:before {
    content: '';
    width: 20px;
    height: 32px;
    border-radius: 4px;
    background-color: #dadada;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 200ms ease-in-out;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .05);
    z-index: 2;
  }
  &[data-current-state="1"], &[data-current-state="0"] {
    &:before {
      width: 36px;
      height: 36px;
      box-shadow: none;
    }
  }

  &[data-current-state="1"] {
    span:last-of-type {
      color: #fff;
    }
    &:before {
      background-color: $success;
      left: 75px;
    }
  }
  &[data-current-state="0"] {
    span:first-of-type {
      color: #fff;
    }
    &:before {
      background-color: $danger;
      left: 16px;
    }
  }
  &--light-no {
    &[data-current-state="0"] {
      &:before {
        background: $warning;
      }
    }
  }

  &--double {
    span:nth-child(2) {
      width: 34px;
    }
    &[data-current-state="1"] {
      &:before {
        left: 50px;
      }
    }
  }
}
