.payment-summary {
  &--margin-bottom {
    margin-bottom: 40px;
  }
  .bigger-font {
    font-size: rem-calc(18);
  }
  h5 {
    margin-bottom: 1px;
    color: #a7a7a7;
    font-weight: 400;
  }
  .balance {
    @include rwd(phone) {
      text-align: center;
    }
  }
  .payment-time {
    margin-top: 10px;
    @include rwd(phone) {
      text-align: center;
    }
  }
  .payment-pay {
    margin-top: 15px;
  }
}