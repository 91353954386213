.detail-table {
	width: 100%;
	tr {
		th {
			text-align: right;
			padding-right: 10px;
			font-weight: 500;
			color: #888;
			width: 40%;
			&:after {
				content: ':';
			}
		}
		td {
			font-weight: 700;
		}
	}
}