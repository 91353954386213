.msg {
  background: #fff;
  border-radius: 1px 5px 3px 1px;
  text-align: left;
  margin: 15px 0;
  padding: 17px 20px;
  @include font(14, 16, 0);
  @include rwd(phone) {
    padding: 10px 20px;
    @include font(13, 15, 0);
  }
  a:not(.btn) {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  &--neutral {
    border-left: 3px solid $gray-500;
    &, a:not(.btn) {
      color: #666;
    }
  }
  &--danger {
    border-left: 3px solid $danger;
    &, a:not(.btn) {
      color: darken($danger, 10);
    }
  }
  &--success {
    border-left: 3px solid $success;
    &, a:not(.btn) {
      color: darken($success, 10);
    }
  }
  &--warning {
    border-left: 3px solid $warning;
    &, a:not(.btn) {
      color: darken($warning, 14);
    }
  }
  &--info {
    border-left: 3px solid $info;
  }
}
.popup__content {
  .msg {
    padding: 7px 15px;
    font-size: 13px;
  }
}
