.page-component {
    padding: 15px 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,.02);
    background-color: #fff;
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(25);
    border-radius: 10px;
    color: $text;
    @include font(14, 18, 0);
    font-weight: 500;
    @include rwd(medium) {
        box-shadow: 0 2px 8px rgba(0,0,0,.05);
    }
    @include rwd(phone) {
        padding: 10px;
    }
    &--reset {
        padding: 0;
        box-shadow: none;
        background: transparent;
        margin: 0;
        border-radius: 0;
    }
    &--smaller {
        padding: 10px 15px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__title {
        @include font(18, 22, 0);
        font-weight: 600;
        color: $text;
    }
    &--green {
        background-color: $success;
        color: #fff !important;
    }
    &--yellow {
        background-color: $warning;
        color: #fff !important;
    }
    &--red {
        background-color: $danger;
        color: #fff !important;
    }
    &--gray {
        background-color: $gray-200;
        color: $primary-dark !important;
    }
    &--transparent {
        background-color: transparent;
        color: $primary-dark !important;
    }
    &--no-padding-bottom {
        padding-bottom: 0;
    }

  &__actions {
    margin-bottom: 20px;
    &--bottom {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }
}
