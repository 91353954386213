.color-picker-widget {
  padding: 10px;
  width: 170px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 99;
  background-color: #fff;
  margin-top: 5px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  &.is-open {
    opacity: 1;
    transform: scaleY(1);
  }
  &__color {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: .3s linear;
    position: relative;
    &--selected {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 170px;;
      height: 25px;
      margin-bottom: 2px;
      pointer-events: none;
    }
  }
}