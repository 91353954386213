.module-disabled {
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    margin-top: 50px;

    @include rwd(phone) {
      padding: 15px;
      margin-top: 20px;
    }
  }

  &__head {
    text-align: center;
  }

  h2 {
    color: $theme;
    margin-bottom: 30px;
    margin-top: -10px;
  }

  .fal {
    font-size: 70px;
    color: $theme;
    margin-bottom: 30px;
  }

  &__message {
    background: $theme-secondary;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    padding: 8px 16px;
  }

  &__features {
    margin-top: 30px;

    h4 {
      margin-bottom: 6px;
    }
    ul {
      margin: 0 0 20px 0;
      padding: 0;
      li {
        list-style: none;
        padding: 1px 0 1px 16px;
        position: relative;
        margin: 0;

        &:before {
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: $theme-secondary;
        }
      }
    }
  }
}
