.modal-box {
  @include flex(center, center, false, false, false);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;

  @include rwd(tablet) {
    padding: 25px 25px 25px 25px;
  }

  @include rwd(small) {
    padding: 10px 10px 10px 10px;
  }

  &__container {
    position: relative;
    border-radius: 4px;
    background: $white;
    max-height: 100%;
    width: 700px;
    overflow: auto;

    @include rwd(medium) {
      width: 100%;
    }
  }

  &__title {
    @include flex(space-between, center, false, false, false);
    position: sticky;
    top: 0;
    width: 100%;
    padding: 8px 10px 8px 10px;
    background: $theme;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    button {
      color: #fff;
      flex-shrink: 0;
      margin-left: 10px;

      #{$icon-selector} {
        font-size: 16px;
        padding-top: 1px;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 15px 20px 15px 20px;
  }
}