$columns: 12;
$gap: 15px;

$rwd: (
  large,
  tablet,
  medium,
  phone,
  small
);

$grid-breakpoints: (
    large: lg,
    tablet: tb,
    medium: md,
    phone: ph,
    small: xs
);

.grid {
  @include flex(flex-start, flex-start, true);

  display: flex !important;

  &--stretch {
    align-items: stretch;
  }

  &--gap {
    &-y {
      &-larger {
        @for $i from 1 through $columns {
          .w#{$i} {
            margin-top: #{$gap} !important;
            margin-bottom: #{$gap} !important;
          }
        }
      }
      &-smaller {
        @for $i from 1 through $columns {
          .w#{$i} {
            margin-top: #{$gap / 4} !important;
            margin-bottom: #{$gap / 4} !important;
          }
        }
      }
    }

    margin: 0 #{$gap / 2 * -1};
    @for $i from 1 through $columns {
      .w#{$i} {
        flex: 0 0 calc(#{percentage($i / $columns) - 0.01} - #{$gap});
        width: calc(#{percentage($i / $columns) - 0.01} - #{$gap});
        max-width: calc(#{percentage($i / $columns) - 0.01} - #{$gap});
        margin: #{$gap / 2};
      }
    }
    @each $breakpoint in $rwd {
      @if map-has-key($grid-breakpoints, $breakpoint) {
        @include rwd($breakpoint) {
          .#{map-get($grid-breakpoints, $breakpoint)} {
            &-hidden {
              display: none;
            }
            @for $i from 1 through $columns {
              &--w#{$i} {
                flex: 0 0 calc(#{percentage($i / $columns) - 0.01} - #{$gap});
                width: calc(#{percentage($i / $columns) - 0.01} - #{$gap});
                max-width: calc(#{percentage($i / $columns) - 0.01} - #{$gap});
                margin: #{$gap / 2};
              }
            }
          }
        }
      }
    }
  }

  &--margin {
    &-y {
      &-bottom {
        margin-bottom: 25px;

        @include rwd(tablet) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@for $i from 1 through $columns {
  .w#{$i} {
    flex: 0 0 percentage($i / $columns) - 0.01;
    width: percentage($i / $columns) - 0.01;
    max-width: percentage($i / $columns) - 0.01;

    &.right {
      text-align: right;
      &[data-sortable] {
        justify-content: flex-end;
      }
    }
  }
}

@each $breakpoint in $rwd {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    [data-#{map-get($grid-breakpoints, $breakpoint)}-before] {
      &:before {
        content: attr(data-#{map-get($grid-breakpoints, $breakpoint)}-before);
        color: $gray-500;
        padding-right: 3px;
        display: inline-block;
      }
    }
  }
}

@each $breakpoint in $rwd {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @include rwd($breakpoint) {
      .#{map-get($grid-breakpoints, $breakpoint)} {
        &-hidden {
          display: none !important;
        }
        &-left {
          text-align: left !important;
        }
        @for $i from 1 through $columns {
          &--w#{$i} {
            flex: 0 0 percentage($i / $columns) - 0.01;
            width: percentage($i / $columns) - 0.01;
            max-width: percentage($i / $columns) - 0.01;
          }
          &-w#{$i} {
            flex: 0 0 percentage($i / $columns) - 0.01;
            width: percentage($i / $columns) - 0.01;
            max-width: percentage($i / $columns) - 0.01;
          }
        }
      }
    }
    @include rwd($breakpoint, true) {
      .#{map-get($grid-breakpoints, $breakpoint)} {
        &-visible {
          display: none !important;
        }
      }
    }
  }
}
