.permissions {
  table {
    width: 100%;
    td, th {
      padding: 6px 10px;
      @include font(13, 15, 0);
      @include rwd(phone) {
        @include font(11, 14, 0);
        padding: 6px;
      }
      &:not(:first-child) {
        text-align: center;
        width: 15%;
        @include rwd(phone) {
          width: 10%;
        }
      }
      &:first-child {
        width: 55%;
        @include rwd(phone) {
          width: 70%;
        }
      }
    }
    th {
      color: $primary;
      padding: 10px;
      @include rwd(phone) {
        padding: 40px 10px;
        position: relative;
        span {
          transform: translateX(-50%) rotate(90deg);
          transform-origin: center;
          display: inline-block;
          position: absolute;
          left: 50%;
          top: 30px;
        }
        &:first-child {
          padding-left: 6px;
          span {
            transform: none;
            left: auto;
            top: auto;
          }
        }
      }
    }
    td {
      &:first-child {
        font-weight: 500;
      }
    }
  }
  .privilege {
    width: 9px;
    height: 9px;
    border-radius: 7px;
    overflow: hidden;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    &--access {
      background-color: $primary;
    }
    &--denied {
      background-color: #c3c3c3;
    }
  }
  tr {
    &:nth-child(even) {
      td {
        background-color: #f9f9f9;
      }
    }
  }
}