// config for inputs

$input-background: #fff;

$input-font-size: rem-calc(14);
$input-font-weight: 500;
$input-font-color: $text;

$input-placeholder-color: $gray-700;
$input-placeholder-weight: 400;

.input-helper {
  position: relative;
  margin-top: 5px;
  margin-bottom: 20px;

  &.is-focused,
  &.always-focused{
    & > label {
      top: 0;
      color: $primary;
      font-size: rem-calc(11);
    }

    & > div {
      & + label {
        top: 0;
        color: $primary;
      }
    }

  }

  &__suffix {
    position: absolute;
    right: 10px;
    top: 1px;
    background: #fff;
    padding-top: 0 !important;
    padding-left: 5px;
    height: 38px;
    line-height: 38px;
    &--disabled{
      background: #e9ecef;
    }
  }

  &__error {
    font-size: 12px;
    color: $danger;
    padding-top: 4px !important;
    padding-bottom: 4px;
  }

  &--select {
    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 19px;
      width: 0;
      height: 0;
      border-top: 5px solid $input-placeholder-color;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      pointer-events: none;
      z-index: 2;
    }
  }

  &--file {
    .single-file-uploader {
      &__select-new {
        position: relative;

        label {
          @extend .btn;
          @extend .btn--smaller;
          position: relative;
          left: auto;
          right: auto;
          top: auto;
          bottom: auto;
          transform: none;
          pointer-events: auto;
          cursor: pointer;
          width: auto;
          display: inline-block;
          margin-top: 5px;
          padding-left: 28px;

          &:before {
            @include faIcon(fas, file-upload);
            position: absolute;
            z-index: 1;
            pointer-events: none;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            color: #fff;
          }
        }

        input {
          position: absolute;
          opacity: 0;
          z-index: -1;
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          border: none;
          outline: none;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  @include on-event() {
    input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], input[type="time"], select, textarea, .input__suffix, .input__prefix {
      border-color: #cfd2d5;
    }
  }
  @-webkit-keyframes autofill {
    to {
      background: transparent;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    color: $input-font-color !important;
  }

  input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], input[type="time"], select, textarea,
  .select2-selection--single{
    background: #fff;
    outline: none;
    box-shadow: none;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    height: 40px;
    color: $input-font-color !important;
    background: $input-background;
    width: 100%;
    padding: 0 10px;
    transition: border .3s linear;
    -webkit-appearance: none;
    &.input-helper__disabled{
      background: #e9ecef;
      font-weight: bold;
    }
  }

  textarea {
    padding-top: 16px;
    padding-bottom: 15px;
    min-height: 55px;
    resize: none;
  }

  select, .select2-selection--single {
    font-size: $input-font-size;
    color: $input-placeholder-color;
    font-weight: $input-placeholder-weight;
    text-indent: 0;
    @include reset('select');
    padding-right: 28px;

    & + label {
      top: 0;
      color: $primary;
      font-size: rem-calc(11);
    }
  }

  & > label, .checkbox label, .radio label {
    display: block;
    position: absolute;
    top: 20px;
    left: 8px;
    z-index: 9;
    padding: 0 2px;
    background: #fff;
    transform: translateY(-50%);
    font-size: $input-font-size;
    color: $text;
    font-weight: $input-placeholder-weight;
    margin: 0;
    transition: top .1s linear, color .15s linear;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
  }

  // radio
  & > div {
    padding-top: rem-calc(15);

    & + label {
      position: absolute;
      left: auto;
      right: auto;
      top: 0 !important;
      bottom: auto;
      transform: none;
      pointer-events: auto;
      color: $primary;
      font-size: rem-calc(11);
    }
  }

  .checkbox, .radio {
    padding: 5px 0;
    cursor: pointer;

    label {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      transform: none;
      pointer-events: auto;
      color: $input-placeholder-color;
      cursor: pointer;
      width: auto;
      display: inline-block;

      input[type="checkbox"], input[type="radio"] {
        position: absolute;
        left: 0;
        top: 2px;
        opacity: 0;

        &:checked + span {
          &:after {
            opacity: 1;
          }
        }
      }

      span {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(18);
        font-size: $input-font-size;
        font-weight: $input-font-weight;
        pointer-events: none;

        a {
          pointer-events: auto;
        }

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          border: 1px solid $gray-500;
          display: block;
          position: absolute;
          left: 0;
          top: 2px;
          border-radius: 3px;
        }

        &:after {
          content: '';
          width: 6px;
          height: 6px;
          background-color: $primary;
          position: absolute;
          left: 3px;
          top: 5px;
          border-radius: 1px;
          opacity: 0;
          transition: .3s linear;
        }
      }
    }
  }

  .radio {
    display: inline-block;

    label {
      padding-right: 10px;

      span {
        &:before {
          content: '';
          border-radius: 50%;
        }

        &:after {
          content: '';
          border-radius: 50%;
        }
      }
    }
  }

  &.input-search {
    margin-top: 12px;
    margin-bottom: 0;

    #{$icon-selector} {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $gray-500;
    }

    input {
      height: 34px;
      padding: 0 rem-calc(10) 0 30px;
    }
  }

  // obsługa komunikatów

  &.error {
    input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], select, textarea {
      //border-top: $input-border-top;
      //border-right: $input-border-right;
      //border-bottom: $input-border-bottom;
      //border-left: $input-border-left;
      //border-radius: $input-border-radius;
      border-color: $danger;
    }

    & + .input-description {
      color: $danger;
    }
  }

  // cke-editor
  .cke_textarea_inline {
    background: #fff;
    outline: none;
    box-shadow: none;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    color: $input-font-color !important;
    background: $input-background;
    width: 100%;
    padding: 15px 10px;
    transition: border .3s linear;
    -webkit-appearance: none;
    min-height: 40px;
    & ~ label {
      top: 0;
      color: $primary;
      font-size: rem-calc(11);
    }
  }
  .cke_editable {
    outline: none;
  }
}

.input-description {
  @extend %text-base;
  color: $secondary;
  font-weight: 400;
  margin-top: -12px;
  margin-bottom: 25px;

  a {
    @extend %text-base;
    color: $primary;
    font-weight: 300;
    transition: .3s linear;
    @include on-event() {
      color: $primary-hover;
    }
  }
}

.single-file-uploader {
  &__existing-file {
    margin-top: 8px;

    &__preview {
      display: inline-block;
      margin-right: 12px;
    }

    .btn {
      display: inline-block;
      vertical-align: middle;
    }

    & + .single-file-uploader__select-new {
      display: none;
    }
  }
}

.input-cleaning {
  position: relative;

  &__button {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 4px;
  }
}

.checkbox-columns {
  column-count: 4;

  @include rwd(tablet) {
    column-count: 3;
  }

  @include rwd(phone) {
    column-count: 2;
  }

  .checkbox {
    break-inside: avoid-column;
  }
}

.checkbox-inline {
  .checkbox {
    display: inline-block;
    margin-right: 10px;
  }
}

[data-changer-hidden]
{
  display: none;
}