.child-box {
  display: flex;
  align-items: center;
  margin-top: 20px;
  @include rwd(tablet) {
    margin-top: 10px;
  }
  @include rwd(small) {
    margin-top: 0;
  }
  &__left {
    padding-right: 10px;
  }
  &__name {
    @include font(15, 18, 0);
    font-weight: 500;
    color: $text;
  }
  &__info {
    @include font(12, 15, 0);
    color: $secondary;
    * {
      @include font(12, 15, 0);
    }
  }
}
