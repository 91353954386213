.transactions {
  &__row {
    @include flex(flex-start, center, false);
  }
  &-confirm {
    margin-top: 15px;
    margin-bottom: 25px;
    @include rwd(small) {
      text-align: center;
    }
  }
}

.transaction {
  &__item {
    margin-bottom: 5px;
    @include flex(flex-start, flex-start, false);
    color: $gray-600;
    @include font(12, 12, 0);
    #{$icon-selector} {
      margin-right: 7px;
      color: $gray-500;
    }
    span {
      display: inline-block;
    }
  }
  &__confirm {
    flex-shrink: 0;
    padding-right: 15px;
    margin-left: -12px;
    @include rwd(medium) {
      margin-left: 0;
      padding-right: 10px;
    }
  }
  &__sender {
    font-weight: 600;
    @include font(13, 13, 0);
  }
  &__description {
    color: $text;
    font-weight: 600;
    @include font(13, 13, 0);
  }
  &__amount {
    color: $primary;
    font-weight: 600;
    #{$icon-selector} {
      color: $primary;
    }
  }
}

.child-to-save {
  position: relative;
  @include rwd(medium) {
    margin-top: 10px;
  }
/*  #{$icon-selector} {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-600;
    font-size: 16px;
  }*/
/*  .input-helper, .autocomplete__content {
    margin-left: 22px;
  }*/
}

.save-child {
  &--select {
    @include rwd(medium) {
      margin-left: 30px;
    }
  }
  @include rwd(medium) {
    padding-top: 5px;
    border-top: 1px solid #e0e0e0;
    margin-top: 3px;
  }
  &__name {
    color: $text;
    @include font(15, 15, 0);
  }
  &__branch-name {
    color: $secondary;
    @include font(12, 12, 0);
    margin-top: 2px;
  }
  &__bottom {
    margin-top: 5px;
    @include flex(flex-start, center, false);
    &--bigger-margin {
      margin-top: 10px;
    }
    #{$icon-selector} {
      color: $primary;
      margin-right: 5px;
      flex-shrink: 0;
    }

    &--counting {
      @include font(15, 16, 0);
      padding: 0 20px 0 20px;
    }
  }
  .lds-ellipsis {
    flex-shrink: 0;
    margin: 0 10px 0 0;
  }
}
