$input-gap: rem-calc(15);
$input-small-gap: rem-calc(6);

.form-grid {
  @extend .table-grid;
  display: flex;
  flex-wrap: wrap;
  margin-left: ($input-gap * -1);
  margin-right: ($input-gap * -1);
  width: auto !important;
  margin-bottom: 0;

  @include rwd(small) {
    margin-left: ($input-small-gap * -1);
    margin-right: ($input-small-gap * -1);
  }

  &__item {
    padding-left: $input-gap;
    padding-right: $input-gap;

    @include rwd(small) {
      padding-left: $input-small-gap;
      padding-right: $input-small-gap;
    }
  }

  &__label {
    padding-left: $input-gap;
    padding-right: $input-gap;
    color: $primary;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: rem-calc(15);

    @include rwd(small) {
      padding-left: $input-small-gap;
      padding-right: $input-small-gap;
    }

    &.no-margin-top {
      margin-top: 0;
    }
  }

  &--columns {
    @include rwd(phone) {
      flex-direction: column;
    }
  }

  &__inline {
    @include flex(flex-start, flex-start, false, false, false);
  }

  &__name {
    @include rwd(phone) {
      margin-bottom: 5px;
      margin-left: 5px;
    }
  }
}

.data-filters {
  .input-helper {
    input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], select, textarea {
      border-bottom: none;
      border-radius: 3px;
      box-shadow: 0 0 6px rgba(0, 0, 0, .04);
      padding: 10px;
    }
  }

  .form-grid {
    margin-left: rem-calc(-5);
    margin-right: rem-calc(-5);
    margin-bottom: 0;

    &__item {
      padding-left: rem-calc(5);
      padding-right: rem-calc(5);

      &--label {
        line-height: 40px;
      }

      &--inline {
        display: flex;

        * {
          &:first-child {
            margin-right: 5px;
          }

          &:nth-child(2) {
            flex: 1 0 auto;
          }
        }
      }
    }
  }
}

.form-actions {
  display: flex;
  flex-wrap: wrap;

  .btn {
    margin-right: 10px;

    &--remove, &--archive {
      margin: 0 0 0 auto;
    }
  }

  @include rwd(small) {
    flex-wrap: wrap;
    justify-content: center;
    & .btn--blue {
      order: 2;
      margin: 10px;
    }
    & .btn--transparent {
      order: 1;
      margin: 10px;
    }
    & .btn--remove, .btn--archive {
      order: 3;
      margin-top: 50px;
      width: 100%;
    }
  }

  &--justify {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: -2px;
    margin-right: -2px;

    .btn {
      margin-top: 0;
      margin-left: 2px;
      margin-right: 2px;
      width: auto;
    }
  }

  &--sticky {
    background-color: $theme-bg;
    position: sticky;
    bottom: 0;
    z-index: 10;
    border-top: 1px solid $theme-secondary-ultralight;
    padding-top: 10px;
    padding-bottom: 10px;

    @include rwd(tablet) {
      bottom: 60px;
    }

    @include rwd(small) {
      padding-top: 5px;
      padding-bottom: 5px;

      .btn--remove, .btn--archive {
        margin-top: 10px;
      }
    }
  }
}

.form-collection-wrapper {
  margin-bottom: 20px;

  &--small {
    margin-bottom: 5px;
  }

  &__button {
    @include rwd(medium) {
      text-align: center;
    }

    &--gap {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .page-component:last-of-type {
    margin-bottom: 10px;
  }
}

.form-collection {
  position: relative;

  &__item {
    display: flex;
    align-items: baseline;

    &--border {
      border-bottom: 1px solid $gray-200;
      padding-bottom: 20px !important;
      margin-bottom: 20px;
      @include rwd(phone) {
        padding-bottom: 25px !important;
      }
    }

    &--subcollection-border {
      @include rwd(medium) {
        padding-bottom: 5px !important;
        margin-bottom: 25px !important;
      }
      @include rwd(phone, true) {
        border: none !important;
      }
      @include rwd(phone) {
        border-bottom: 1px solid $gray-200;
        padding-bottom: 10px;
        margin-bottom: 20px;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        .input-helper {
          margin-bottom: 13px;
        }
      }
    }

    @include rwd(medium) {
      border-bottom: 1px solid $gray-200;
      padding-bottom: 10px;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .input-helper {
        margin-bottom: 13px;
      }
    }

    &__form {
      position: relative;
      flex-grow: 1;

      &--dad {
        @include rwd(phone) {
          padding-left: 10px;
        }
        @include rwd(small) {
          padding-left: 17px;
        }
      }
    }

    &__remove {
      .btn {
        margin-left: 10px;
        transform: translateY(3px);
        &:before {
          transform: translate(-0.5px, -2px);
        }
      }

      .button {
        margin-left: 25px;
        @include rwd(phone) {
          margin-left: 10px;
        }
      }
    }
  }

  &__description {
    position: absolute;
    bottom: -20px;
    left: 0;
    @include rwd(phone) {
      bottom: -25px;
    }
  }

  &__absolute-icon {
    position: absolute;
    left: -33px;
    top: 14px;
    color: $gray-500;
    cursor: pointer;

    #{$icon-selector} {
      font-size: 24px;
    }

    @include rwd(phone) {
      left: -19px;
    }

    &--small {
      left: -29px;
      top: 16px;

      #{$icon-selector} {
        font-size: 20px;
      }

      @include rwd(phone) {
        left: -15px;
      }
    }
  }
}

// new after redesign
.form-layout {
  margin: -16px -1% 0 -1% !important;

  @include flex(flex-start, flex-start, true);

  .field {
    margin-top: 16px;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }

  &__footer {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 16px;
  }
}

.fields-visual-choice {
  @include flex(flex-start, flex-start, true);
  margin-left: -4px;
  margin-right: -4px;
}

.textarea-limiter {
  max-height: 150px;
  overflow-y: scroll !important;
}
