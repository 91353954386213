.contact-persons {
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;

  &__item {
    margin-bottom: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #f3f3f3;

    &:last-of-type {
      border: none;
    }
  }

  &__name {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  &__contact {
    margin-right: 10px;

    #{$icon-selector} {
      color: #aaa;
      margin-right: 3px;
    }
  }
}
