.app-calendar {
  margin-top: rem-calc(35);
  @include rwd(phone) {
    margin-top: rem-calc(20);
  }

  table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    border-collapse: collapse;
  }

  .fc-header-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;

    .fc-left {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fc-center, .fc-clear {
      display: none;
    }

    .fc-prev-button, .fc-next-button {
      outline: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      box-shadow: none;
      background: transparent;

      .fc-icon {
        &:before {
          @include faIcon(fas, angle-left);
          color: $primary;
          opacity: 1;
          outline: none;
          transition: .3s ease-in-out;
          cursor: pointer;
          font-size: 24px;
        }
      }

      @include on-event() {
        .fc-icon {
          &:before {
            opacity: 1;
          }
        }
      }
    }

    .fc-next-button {
      .fc-icon {
        &:before {
          @include faIcon(fas, angle-right);
          font-size: 24px;
        }
      }
    }

    .title {
      @include font(14, 17, 0);
      color: $secondary;
      text-align: center;
      min-width: 205px;

      span {
        display: block;
        font-weight: 400;

        &:first-child {
          font-weight: 400;
          @include font(18, 17, 0);
          margin-bottom: rem-calc(8);
          color: rgba($primary, .7);
        }
      }
    }
  }

  .fc-day-header {
    span {
      color: rgba($primary, .7);
      font-weight: 400;
      @include font(14, 17, 0);
    }
  }

  .fc-row.fc-week {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .fc-widget-header {
    margin-bottom: rem-calc(5);
    margin-top: rem-calc(15);
  }

  .fc-scroller {
    overflow: visible !important;
  }

  .fc-day-number {
    @include font(14, 16, 0);
    color: #818286;
    font-weight: 600;
    display: block;
    text-align: right;
    padding-top: 8px;
    padding-right: 8px;
    @include rwd(medium) {
      padding-right: 4px;
      padding-top: 4px;
      @include font(13, 15, 0);
    }
  }

  .fc-sat, .fc-sun, .day-off {
    .fc-day-number {
      color: #cecece;
    }
  }

  .fc-other-month {
    .fc-day-number {
      opacity: .5;
    }
  }

  .fc-content-skeleton {
    line-height: 0;

    & > table {
      height: 100%;
    }

    td, tr {
      line-height: 0;
      padding: 0;
      margin: 0;
    }
  }

  .fc-event-container {
    position: relative;
    line-height: 0;
    margin: 0;
    padding: 0;
  }

  .fc-today {
    .fc-day-number {
      color: $calendar-today;
      opacity: 1;
    }
  }

  // loader
  .fc-view-container {
    position: relative;
    transition: .2s linear;
  }

  &.is-loading {
    .fc-view-container {
      transition: .4s linear;

      &:before {
        content: '';
        position: absolute;
        left: -5px;
        top: 0;
        right: -5px;
        bottom: -5px;
        z-index: 90;
        border-radius: 5px;
        background-color: rgba(#f8f9fd, 1);
        @include rwd(phone) {
          background-color: #fff;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 99;
        margin-left: -10px;
        margin-top: -10px;
        @extend .loader;
      }
    }
  }

  .day-events {
    cursor: pointer;
    position: relative;
  }

  .fc-row:not(.fc-widget-header) {
    min-height: rem-calc(40);
    position: relative;

    .fc-bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      table {
        height: 100%;

        td {
          background-color: #ffffff;
          border: 1px solid #ebebeb;
        }
      }

      .fc-sat, .fc-sun, .day-off {
        background-color: $calendar-day-off;
      }

      .fc-day {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 50px;
          height: 28px;
          border-radius: 20px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          @include rwd(phone) {
            width: 30px;
            height: 20px;
            transform: translate(-50%, -30%);
          }
        }

        &:before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin-bottom: 2px;
          z-index: 3;
          font-size: rem-calc(12);
          background-color: transparent;
          opacity: 0;
          @include rwd(phone) {
            transform: translate(-50%, -10%);
            font-size: rem-calc(10) !important;
          }
        }
      }
    }

    .fc-content-skeleton {
      position: absolute;
      z-index: 2;
      pointer-events: none;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  .fc-event {
    width: 100%;
    height: 6px;
    background-color: $primary;
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    pointer-events: none;
    display: none;

    .fc-content {
      display: none;
    }

    &.day-off {
      display: block;
      background-color: transparent;

      .fc-content {
        display: block;
        margin: 0;
        max-width: 100%;
        padding: 0;
        box-shadow: none;
        border-radius: unset;
        font-size: rem-calc(11);
        padding-top: 2px;
        color: $secondary;
        font-weight: 300;
        padding-left: 5px;
        padding-right: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        margin-top: -12px;
        @include rwd(1300px) {
          display: none;
        }
      }
    }
  }

  @include rwd(phone) {
    .fc-scroller.fc-day-grid-container {
      height: 300px !important;
    }
    .fc-row.fc-week.fc-widget-content {
      height: 50px !important;
    }
  }

}

.child-presence-calendar {
  .fc-content-skeleton {
    display: none;
  }

  .fc-head-container {
    background-color: $white;
  }

  .fc-header-toolbar {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 12px;
    margin-bottom: 16px !important;

    .fc-left > .title {
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      column-gap: 4px;
      margin-left: 4px;

      span {
        color: $gray-900 !important;
        font-size: rem-calc(18) !important;
        font-weight: 600 !important;
        margin-bottom: 0px !important;

        &:first-child:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .fc-right {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: 4px;

      .fc-button {
        width: 32px !important;
        height: 32px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white !important;
        border-radius: 0.25rem;

        .fc-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            color: $gray-900 !important;
            font-weight: 400 !important;
          }
        }
      }
    }
  }

  .fc-day:after {
    content: none !important;
  }

  .fc-day.day-off {
    background-color: $white !important;
  }

  .fc-future, .fc-today:not(.day-off), .fc-past.allow-event {
    cursor: pointer;
  }

  [data-day-cell] {
    border-radius: 9999px !important;
    width: 3rem !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  [data-event] {
    width: 100%;
  }

  .fc-scroller.fc-day-grid-container {
    height: 375px !important;
  }
  .fc-row.fc-week.fc-widget-content {
    height: 75px !important;
  }
}

.free-days-calendar {
  .fc-row:not(.fc-widget-header) {
    .fc-bg {
      .day-off {
        cursor: pointer;
        @include on-event() {
          background: darken($calendar-day-off, 3);
        }
      }
      table {
        td {
          &.fc-day:not(.fc-sat):not(.fc-sun):not(.day-off) {
            transition: .2s;
            cursor: pointer;
            &:before {
              @include faIcon(fas, plus);
              color: $primary;
              opacity: 0;
              transition: opacity 300ms;
            }
            @include on-event() {
              background: #f9f9f9;
              &:before {
                opacity: 1;
              }
            }
          }
          &.day-off {
            transition: .2s;
            &:before {
              @include faIcon(fas, check);
              color: $primary;
              opacity: 1;
              @include rwd(1300px, true) {
                display: none;
              }
            }
            @include on-event() {
              background: #f9f9f9;
            }
          }
        }
      }
    }
    .fc-content {
      .fc-title {
        color: $primary-dark;
      }
    }
  }
}

.calendar-legend {
  $legend: &;

  &.hidden {
    opacity: 0;
  }

  &__layout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  @include rwd(medium) {
    justify-content: center;
  }
  @include rwd(small) {
    margin-left: 0;
    margin-right: 0;
    margin-top: rem-calc(5);
  }

  &__item {
    margin: 10px 0;
    position: relative;
    font-weight: 500;
    padding: rem-calc(2) 30px;
    font-size: rem-calc(13);
    color: #545454;
    @include rwd(tablet) {
      font-size: rem-calc(12);
    }
    @include rwd(medium) {
      width: 50%;
    }
    @include rwd(phone) {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      width: 50px;
      height: 28px;
      border-radius: 20px;
      left: 0;
      top: -3px;
      opacity: 1;
      z-index: 0;
    }

    &.present {
      color: $status_present;

      &:after {
        background-color: lighten($status_present, 40);
      }
    }

    &.absent {
      color: $status_absent;

      &:after {
        background-color: #FADFDF;
      }
    }

    &.reported {
      color: $status_reported;

      &:after {
        background-color: lighten($status_reported, 37);
      }
    }

    &.cancel-dishes {
      color: $primary;

      &:after {
        background-color: lighten($primary, 26);
      }

      #{$icon-selector} {
        font-size: 20px;
        top: 1px;
        left: 16px;
      }
    }
  }

  &--icons {
    #{$legend}__item {
      #{$icon-selector} {
        position: absolute;
        z-index: 2;
        font-size: rem-calc(12);
        left: 20px;
        top: 6px;
      }

      span {
        padding-left: 25px;
        position: relative;
        z-index: 2;
        display: inline-block;
      }
    }
  }
}

.calendar-heading {
  @include flex(space-between, center, true);
  @include rwd(phone) {
    justify-content: center;
    &:after {
      content: '';
      display: block;
      width: 100%;
      order: 2;
    }
  }
  &__left {
    padding-right: 30px;
    margin-bottom: 0;
    @include rwd(phone) {
      order: 1;
    }
  }
  &__right {
    @include rwd(phone) {
      order: 3;
    }
  }
}

.calendar.fc {
  .fc-view-harness {
    background: #fff;
  }

  .fc-toolbar.fc-header-toolbar {
    @include rwd(phone) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
  }

  .fc-toolbar-chunk:nth-child(1), .fc-toolbar-chunk:nth-child(2) {
    @include rwd(phone) {
      order: 2;
    }
  }

  .fc-toolbar-chunk:nth-child(2) {
    @include rwd(phone) {
      order: 1;
      flex-grow: 1;
    }
  }

  .fc-toolbar-chunk:nth-child(3) {
    @include rwd(phone) {
      order: -1;
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
