.children-list-table {
  flex-flow: column wrap;

  &__group-head {
    padding-left: 12px !important;
  }

  @include rwd(phone) {
    .row {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .avatar {
      top: 0;
      transform: none;
    }
    &__group {
      order: 2;
      padding-left: 43px !important;
      margin-top: -19px;
      pointer-events: none;
    }
  }
}

.table-workers {
  @include rwd(tablet) {
    .table-actions {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.guardian-list-table {
  @include rwd(phone) {
    flex-flow: column wrap;
    .avatar {
      top: 0;
      transform: none;
    }
    &__child {
      order: 2;
      padding-left: 56px !important;
      margin-top: 5px;
      color: $secondary;
      pointer-events: none;

      a {
        color: $secondary;
      }
    }
  }
}

.charges-table {
  @include rwd(small) {
    .row {
      align-items: flex-start;

      & > div {
        &:first-child {
          order: -2;
          padding-bottom: 15px;
        }
      }
    }
    .promoted-column {
      order: -2;
      padding-bottom: 15px;
    }
    .table-actions {
      order: -1;
    }
  }
}

.expanded-table {
  .row:not(.head) {
    cursor: pointer;
    /*&:before {
        top: 18px;
    }*/
    // TOVERIFY: na razie to komentuje, aby numer środkował się vertykalnie
  }

  &__expand {
    margin-top: 11px;
    padding-top: 10px;
    padding-bottom: 6px;
    border-top: 1px solid #f3f3f3;
    flex-wrap: nowrap;
    display: none;
    cursor: initial;

    &.expanded {
      display: flex;
    }

    strong {
      color: $text;
    }
  }
}

.table-presence-reports {
  .row {
    position: relative;

    &:not(.head) {
      > div {
        @include rwd(large) {
          &:nth-child(1) {
            order: 1;
          }
          &:nth-child(2) {
            order: 2;
            margin-top: 5px;
          }
          &:nth-child(3) {
            order: 4;
            margin-top: 10px;
            color: #191919;
          }
          &:nth-child(4) {

          }
          &:nth-child(5) {
            order: 3;
            margin-top: 5px;
          }
          &:nth-child(6) {

          }
        }
      }
    }
  }

  .table-actions {
    @include rwd(large) {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

.table-import-history {
  .row {
    &:not(.head) {
      > div {
        @include rwd(large) {
          &:nth-child(1) {
            padding-bottom: 5px;
            border-bottom: 1px solid #efefef;
            @include font(11, 14, 0);
          }
          &:nth-child(2) {
            padding-top: 5px;
          }
          &:nth-child(3) {
            padding-top: 5px;
          }
          &:nth-child(4) {
            margin-top: 1px;
          }
        }
      }
    }
  }
}

.table-payments-list {
  .row {
    &:not(.head) {
      > div {
        @include rwd(phone) {
          &:nth-child(1) {
            @include font(11, 14, 0);
          }
          &:nth-child(3) {
            width: 100%;
            flex-basis: 100%;
            padding-top: 5px;
          }
          &:nth-child(4) {
            width: 100%;
            flex-basis: 100%;
            margin-top: 1px;
          }
        }
      }
    }
  }
}

.summary-inline {
  @include flex(flex-start, center, true);

  .summary-item {
    font-weight: 400;
    @include rwd(small) {
      width: 100%;
    }

    &:before {
      content: "";
      display: inline-block;
      padding: 0 10px;
      @include rwd(small) {
        display: none;
      }
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }
}
