.child-view {
  $selector: &;
  display: flex;
  position: relative;
  align-items: flex-start;
  &--page-component {
    @include rwd(medium) {
      padding: 0 !important;
      margin-top: 50px !important;
    }
  }
  @include rwd(medium) {
    flex-wrap: wrap;
  }
  &__title {
    flex: 0 0 100%;
    width: 100%;
    @include font(14, 18, 0);
    font-weight: 600;
  }
  .view-left {
    border-right: 1px solid $light-primary;
    padding-right: 20px;
    flex: 0 0 65%;
    display: flex;
    flex-wrap: wrap;
    @include rwd(large) {
        flex: 0 0 60%;
    }
    @include rwd(medium) {
      width: 100%;
      flex: 0 0 100%;
      text-align: center;
      justify-content: center;
      padding-right: 0;
    }
  }
  .view-right {
    padding-left: 20px;
    position: sticky;
    top: 80px;
    flex: 0 0 35%;
    z-index: 2;
    @include rwd(large) {
      flex: 0 0 40%;
    }
    @include rwd(tablet) {
      top: 20px;
    }
    @include rwd(medium) {
      width: 100%;
      flex: 0 0 100%;
    }
  }
  &--inner {
    display: flex;
    padding-top: 10px;
    @include rwd(tablet) {
      flex-wrap: wrap;
    }
  }
  .inner-left {
    @include rwd(tablet) {
      flex: 1 1 100%;
    }
    .sticky--helper {
      @include rwd(tablet) {
        display: flex;
        .btn--helper {
          padding-left: 20px;
        }
      }
    }
    .avatar__image {
      @include rwd(medium) {
        width: 80px !important;
        height: 80px !important;
        flex: 0 0 80px !important;
      }
    }
    .avatar__initials {
      font-size: 24px;
    }
    .avatar {
      @include rwd(medium) {
        margin-left: auto;
        margin-right: auto;
        margin-top: -50px;
      }
    }
  }
  .inner-right {
    flex: 1 1 auto;
    padding-left: 20px;
    @include rwd(tablet) {
      flex: 1 1 100%;
      margin-top: 20px;
      padding-left: 0;
      z-index: 2;
    }
  }
  .btn--helper {
    text-align: center;
    margin-top: 20px;
  }
  &__group {
    @include rwd(medium) {
      color: $secondary;
      font-weight: 300;
      @include font(16, 20, 0);
      margin: 20px 0;
      a {
        @include font(16, 20, 0);
        font-weight: 400;
      }
    }
    @include rwd(phone) {
      margin-top: 10px;
    }
  }
  &__actions {
    margin-bottom: 20px;
  }
  &__details {
    @include rwd(medium) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 4px;
    }
  }
  &__detail {
    margin-top: 4px;
    @include rwd(medium) {
      margin-top: 15px;
      text-align: left;
      width: 50%;
      &:nth-child(1), &:nth-child(2) {
        margin-top: 4px;
      }
    }
    @include rwd(phone) {
      width: 100%;
      &:nth-child(2) {
        margin-top: 15px;
      }
    }
    span {
      @include font(14, 16, 0);
      @include rwd(large) {
        @include font(13, 15, 0);
      }
      @include rwd(medium) {
        @include font(14, 18, 0);
        color: $secondary;
        font-weight: 300;
      }
      &:nth-child(2) {
        font-weight: 700;
        color: $text;
        @include rwd(medium) {
          display: block;
          width: 100%;
          @include font(16, 20, 0);
          font-weight: 500;
        }
        @include rwd(phone) {
          @include font(14, 18, 0);
        }
      }
    }
  }
  &__additional {
    margin-top: 20px;
    width: 100%;
    color: $secondary;
    @include rwd(medium) {
      margin-top: 0;
    }
  }
  &__siblings {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    #{$selector}__title {
      margin: 0;
      padding: 0;
    }
  }
  &__sibling {
    flex: 0 0 50%;
    margin-top: 10px;
    padding-right: 10px;
    @include rwd(large) {
        flex: 0 0 100%;
    }
    @include rwd(tablet) {
        flex: 0 0 50%;
    }
  }
  &__parent {
    margin-top: 15px;
    align-items: center;
  }
  &__add {
    margin-top: 20px;
  }
}


.child-parents, .child-siblings {
  @include rwd(medium) {
    margin-bottom: 30px !important;
  }
  .avatar__image {
    @include rwd(medium) {
      width: 50px !important;
      height: 50px !important;
      flex: 0 0 50px !important;
    }
  }
  .avatar--color-theme {
    @include rwd(medium) {
      background-color: #e0eaff;
    }
  }
}
