.paging {
  @include flex(flex-start, center);
  background: $white;
  padding: 15px 0;
  margin: 1px 0 12px 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  @include rwd(phone) {
    justify-content: center;
  }

  &__container {
    @include flex(flex-start, flex-start, false, false);
    background: $white;
    margin-left: 22px;

    @include rwd(medium) {
      margin-left: 7px;
    }

    @include rwd(phone) {
      margin-left: 0;
    }
  }
}