.media-uploader {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
	.uploader-item {
		width: 20%;
		padding: 5px;
		@include rwd(tablet) {
			width: 25%;
		}
		@include rwd(medium) {
			width: 33.3333333%;
		}
		@include rwd(phone) {
			width: 50%;
		}
		&__content {
			height: 100%;
			border-radius: 3px;
			padding: 15px 10px;
			overflow: hidden;
			position: relative;
			box-shadow: 0 0 8px rgba(#000, .1);
			@include rwd(phone) {
				padding: 5px;
			}
			&.uploading {
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba(255,255,255,.6);
					z-index: 1;
				}
				&:after {
					content: 'Wysyłam...';
					position: absolute;
					z-index: 5;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-weight: bold;
					color: $primary;
				}
			}
			&__name {
				font-weight: bold;
				color: $primary;
				text-overflow: clip;
				white-space: nowrap;
				overflow: hidden;
			}
			&__thumbnail {
				margin: -15px -15px 15px -15px;
				padding-top: 75%;
				background: #eee no-repeat center center;
				background-size: cover;
				position: relative;
				overflow: hidden;
				@include rwd(phone) {
					margin: -5px -5px 5px -5px;
				}
				img {
					max-width: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				i {
					max-width: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 2vw;
					color: $primary;
					@include rwd(phone) {
						font-size: 4vw;
					}
				}
			}
			&__remove, &__remove-ajax {
				position: absolute;
				right: 0;
				bottom: 15px;
				cursor: pointer;
				padding-right: 10px;
				background: #fff;
				@include rwd(phone) {
					bottom: 6px;
				}
				&:hover {
					color: $primary;
				}
				&:before {
					content: '';
					position: absolute;
					background: #fff;
					background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%);
					top: 0;
					bottom: 0;
					left: -30px;
					right: 0;
					z-index: 0;
				}
				i {
					position: relative;
					z-index: 1;
				}
			}
			&__error-message {
				position: absolute;
				top: 8px;
				left: 8px;
				right: 8px;
				color: $danger;
				border: 1px solid $danger;
				background: lighten($danger, 90);
				padding: 4px 8px;
				border-radius: 3px;
				z-index: 5;
			}
		}
		input[type=checkbox] {
			display: none;
		}
	}
	.upload-form {
		order: 2;
		&__media {
			height: 100%;
			padding: 15px;
			border-radius: 3px;
			font-size: 14px;
			font-weight: 500;
			text-align: center;
			background: #F1F5FF;
			border: 1px solid $primary;
			color: $primary;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				border-color: $primary-hover;
				color: $primary-hover;
			}
			i {
				font-size: 16px;
				margin-bottom: 10px;
			}
			input {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: red;
				opacity: 0;
				cursor: pointer;
				width: 100%;
				height: 100%;
			}
		}
	}
}
