.payment-methods-page {
  .payment-methods-text {
    margin-top: 15px;
    @include rwd(small) {
      margin-top: 8px;
    }
  }
  .popup-table-info {
    &__table {
      max-width: 500px;
      margin-bottom: 30px;
      table-layout: auto;
      @include rwd(small) {
        margin-bottom: 15px;
      }
      td {
        &:nth-child(1) {
          width: 65%;
        }
        &:nth-child(2) {
          width: 35%;
        }
      }
    }
    &__to-pay {
      background: transparent;
      @include rwd(349px) {
        #{$icon-selector} {
          display: inline-block !important;
        }
        span {
          margin: 0 5px !important;
        }
      }
      td {
        &:nth-child(2) {
          @include rwd(small) {
            padding: 5px;
          }
        }
      }
    }
  }
  .btn--pay {
    @include rwd(small) {
      width: 100%;
    }
  }
}

.payment-methods {
  @include flex(flex-start, stretch, true);
  margin-left: -0.5%;
  margin-right: -0.5%;
  .payment-method {
    width: 15.66%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-top: 15px;
    text-align: center;
    position: relative;
    cursor: pointer;
    @include rwd(large) {
      width: 19%;
    }
    @include rwd(tablet) {
      width: 24%;
    }
    @include rwd(phone) {
      width: 32.33%;
      margin-top: 7px;
    }
    @include rwd(small) {
      width: 49%;
    }

    // general styles
    &__image {
      display: block;
      width: 100%;
      img {
        max-width: 100px;
        @include rwd(small) {
          max-width: 65px;
        }
      }
    }
    &__name {
      display: block;
      width: 100%;
      @include font(13, 16, 0);
      font-weight: 600;
      margin-top: 6px;
    }

    label {
      cursor: pointer;
      border: 2px solid #f3f3f3;
      border-radius: 5px;
      padding: 10px;
      height: 100%;
      width: 100%;
      * {
        pointer-events: none;
      }
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      pointer-events: none;
      z-index: -1;
      &:checked + label {
        border-color: $primary-hover;
      }
    }
  }
}
