.inline-calendar {
  $this: &;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
  padding-top: 30px;
  @include rwd(tablet) {
    margin-top: 10px;
  }
  &__layout {
    @include flex($wrap: false, $ha:center);
    position: relative;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 100px;
    margin-bottom: -110px;
  }
  &__week-day {
    color: $secondary;
    font-weight: 400;
  }
  &__week-prev {
    margin-right: 10px;
    #{$icon-selector} {
      padding-right: 2px;
    }
  }
  &__week-next {
    margin-left: 10px;
    #{$icon-selector} {
      padding-left: 2px;
    }
  }
  &__week-prev, &__week-next {
    #{$icon-selector} {
      font-size: 24px;
    }
  }
  &__week {
    text-align: center;
    font-weight: 500;
    color: $primary-dark;
    margin-bottom: 20px;
    @include font(13, 16, 0);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 6px;
    width: 100%;
  }
  &__current {
    text-align: center;
    font-weight: 500;
    color: $primary-dark;
    margin-top: 20px;
    @include font(13, 16, 0);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6px;
    width: 100%;
  }
  &__separator {
    transform-origin: center;
    @include font(12, 12, 0);
    font-weight: 500;
    color: $primary-light;
    padding: 5px 10px;
    align-self: flex-start;
    position: relative;
    text-align: center;
    flex-basis: 40px;
    flex-shrink: 0;
    &:before {
      content: '';
      display: block;
      width: 3px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: $primary-light;
      height: 14px;
      margin-top: 3px;
      top: 100%;
      border-radius: 1px;
    }
  }
  &__day {
    $day: &;
    @include font(14, 14, 0);
    font-weight: 400;
    color: $secondary !important;
    text-align: center;
    flex-basis: 40px;
    flex-shrink: 0;
    position: relative;
    @include transition(color);
    padding: 5px;
    cursor: pointer;
    border-radius: 7px;
    @include on-event() {
      color: #81a9ff !important;
    }
    &--current {
      #{$this}__day-bottom {
        color: #fff;
        &:before {
          content: '';
          display: block;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: $primary;
          z-index: 1;
        }
      }
    }
    &--disabled {
      cursor: default;
      color: #e0e0e0 !important;
    }
  }
  &__day-top {
    @include font(15, 15, 0);
  }
  &__day-bottom {
    margin-top: 12px;
    position: relative;
    font-weight: 500;
    span {
      position: relative;
      z-index: 2;
    }
  }
}
