$base-font-size: 16;
$base-font-size-rwd: 14;
$heading-scale: 2;
$heading-scale-rwd: 1.5;

.cke-content {
  // global
  &, * {
    @include font(15, 18, 0);
    color: $text;
    font-weight: 400;
  }

  strong {
    font-weight: 700;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  // paragraphs
  p {
    padding: 10px 0;
    margin: 0;
  }

  // links
  a, a * {
    color: $primary;
    @include link-hover();
  }

  // headings
  @for $i from 1 through 6 {
    h#{$i} {
      padding: 10px 0;
      margin: 20px 0 0 0;
      color: $text;
      @include font($base-font-size + $heading-scale * (6 - $i), $base-font-size + $heading-scale * 1.2 * (6 - $i), 0);
      @include rwd(tablet) {
        margin: 10px 0 0 0;
        @include font($base-font-size-rwd + $heading-scale-rwd * (6 - $i), $base-font-size + $heading-scale-rwd * 1.2 * (6 - $i), 0);
      }
    }
  }

  // lists
  ul, ol {
    margin: 10px 0;
  }

  // tables
  table {
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  tr {
    &:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
  th, td {
    text-align: left;
    padding: 5px 10px;
  }

  // images
  img {
    max-width: 100%;
    border-radius: 5px;
    overflow: hidden;
    height: auto !important;
    width: auto !important;
  }

  &--small {
    @include font(13, 15, 0);
    * {
      @include font(13, 15, 0);
    }
  }

  &--gray {
    color: $gray-600;
    * {
      color: $gray-600;
    }
  }

  &--margin-top {
    margin-top: 10px;
  }
}
