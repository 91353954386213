.children-select-list {
  h3 {
    margin-bottom: 0;
  }
  &__group {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    max-height: 190px;
    overflow: auto;
    margin-top: 5px;
    @extend %scrollbar-style;
  }
  &__item {
    flex: 0 0 50%;
    margin-top: 10px;
    padding-right: 10px;
    @include rwd(tablet) {
      flex: 0 0 50%;
    }
    @include rwd(phone) {
      flex: 0 0 100%;
    }
  }
}