.mobile-context-selector {
  background: none;
  margin: 0 -20px;
  display: none;
  background: $theme-bg;
  box-shadow: 10px 0 0 rgba(#000, .1);
  overflow: hidden;
  border-bottom: 1px solid #eee;

  @include rwd(tablet) {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;

    .content--full & {
      margin-left: 0;
      margin-right: 0;
    }

    .messages-view & {
      display: none;
    }
  }

  @include rwd(small) {
    margin-left: -10px;
    margin-right: -10px;
    .content--full & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__item {
    width: 50%;
    position: relative;
    &:first-of-type {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 4px;
        width: 28px;
        height: 28px;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        transform: rotate(-45deg);
      }
    }
    select {
      width: 100%;
      background: none;
      text-align: center;
      height: auto;
      padding: 12px 10px 12px 20px;
      font-size: 12px;
      border: none;
      outline: none;
      color: #888;
      -webkit-appearance: none;
    }
    &--selected {
      select {
        color: $text;
      }
    }
  }
}
