// TODO: Remove after redesign

.btn {
  display: inline-block;
  border-radius: 20px;
  padding: rem-calc(12) rem-calc(20);
  text-align: center;
  color: $white;
  cursor: pointer;
  font-weight: 400;
  overflow: hidden;
  background: $primary;
  outline: none;
  @include font(15, 15, 0);
  border: none;
  position: relative;

  // general styles

  // # on-event actions
  &:not([disabled]) {
    @include on-event() {
      background: $primary-hover;
      color: $white;
    }
  }

  &--simulate-border {
    border: 1px solid $primary;
    &:not([disabled]) {
      @include on-event() {
        border-color: $primary-hover;
      }
    }
  }

  // # disabled
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }

  &--blue-light {
    background: $primary-light;
  }
  &.smaller {
    border-radius: 14px;
    padding: rem-calc(10) rem-calc(16);
  }
  &--smaller {
    border-radius: 14px;
    padding: rem-calc(8) rem-calc(12);
    @include font(13, 13, 0);
  }
  &--border {
    background: transparent;
    border: 2px solid $primary;
    color: $text;
    @include on-event() {
      border-color: $primary-hover;
      color: $white;
      background: $primary-hover;
    }
    &.secondary {
      border-color: $secondary;
      color: $secondary;
      @include on-event() {
        border-color: $secondary-hover;
        color: $white;
        background: $secondary-hover;
      }
    }
  }
  &--white {
    background: $white;
    color: $secondary;
    font-weight: 400;
    box-shadow: 0 0 6px rgba(0,0,0,.04);
    @include font(14, 16, 0);
  }
  &--with-element {
    display: flex;
    align-items: center;
  }
  &--circle-icon {
    background: transparent;
    color: $primary;
    padding: rem-calc(14);
    @include font(16, 16, 0);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    #{$icon-selector} {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    @include on-event() {
      background: $primary;
      color: $white;
    }
    &.secondary {
      color: $secondary;
      @include on-event() {
        background: $secondary;
        color: $white;
      }
    }
    &.dark {
      color: $primary-dark;
      @include on-event() {
        background: $secondary;
        color: $white;
      }
    }
    &.smaller {
      padding: rem-calc(10);
      border-radius: 50%;
    }
    &.smaller-2x {
      padding: 10px;
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }
    svg {
      margin: 0;
    }
    &-fill-primary {
      background: $primary;
      color: $white;
    }
    &-fill-yellow {
      background: $guardian-color;
      color: $white;
    }
    &-fill-secondary {
      background: #eaeaea;
      color: $secondary;
    }
  }

  &--add {
    &:before {
      @include faIcon('fal', 'plus');
    }
  }

  &--edit {
    &:before {
      @include faIcon('fal', 'pencil');
    }
  }

  &--add, &--edit {
    #{$icon-selector} {
      &:before {
        margin-right: rem-calc(3);
      }
    }
  }

  &--prev {
    &:before {
      @include faIcon('fal', 'angle-left');
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      margin-top: 1px;

      @media screen and (max-width: 450px) {
        left: 8px;
      }
    }

    padding-left: 32px !important;

    @media screen and (max-width: 450px) {
      padding-left: 22px !important;
    }
  }

  &--next {
    &:before {
      @include faIcon('fal', 'angle-right');
      font-size: 20px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      margin-top: 1px;

      @media screen and (max-width: 450px) {
        right: 8px;
      }
    }

    padding-right: 32px !important;

    @media screen and (max-width: 450px) {
      padding-right: 22px !important;
    }
  }

  &--archive {
    &:before {
      @include faIcon('fal', 'archive');
    }
  }

  &--remove {
    &:before {
      @include faIcon('fal', 'trash-alt');
    }
  }

  &--archive, &--remove {
    &:before {
      margin-right: rem-calc(3);
    }

    color: $danger;
    background: none;
    @include on-event() {
      color: #fff;
      background-color: $danger;
    }

    &.btn--circle-icon {
      width: 41px;
      text-align: center;
      #{$icon-selector} {
        &:before {
          margin-right: 0;
        }
      }
    }
  }

  &--icon {
    #{$icon-selector} {
      &:before {
        margin-right: rem-calc(3);
      }
    }
  }

  &--warning {
    background-color: $danger;
    color: #ffffff;
    @include on-event() {
      background-color: darken($danger, 10%);
    }
  }
  &--success {
    background-color: $success;
    color: #ffffff;
    @include on-event() {
      background-color: darken($success, 10%) !important;
    }
  }
  &--label {
    display: inline-block;
    border-radius: 20px;
    padding: rem-calc(4) rem-calc(8);
    text-align: center;
    margin: rem-calc(1) rem-calc(1);
    @extend %text-base;
    margin-top: 2px;
    font-weight: 500;
    &:before, &:after {
      font-size: 13px;
    }
  }
  &--transparent {
    border: none;
    background: transparent;
    color: $secondary;
    @include on-event() {
      background: $primary;
      color: #fff;
    }
    &--no-hover {
      @include on-event() {
        border: none;
        background: transparent;
        color: $secondary;
      }
    }
  }
  &--pay {
    @include flex(center, center, false);
    #{$icon-selector} {
      flex-shrink: 0;
      margin-right: 3px;
    }
    .price {
      @include font(13, 13, 0);
      line-height: inherit;
      font-weight: 300;
      padding-left: 4px;
      flex-shrink: 0;
      transform: translateY(1px);
    }
  }
  &--outline {
    border: 1px solid $secondary;
    background: transparent;
    color: $secondary;
    &:not([disabled]) {
      @include on-event() {
        background: $secondary;
        color: $white;
      }
    }
  }
  &--loading {
    &:not(.force-no-loader) {
      position: relative;
      overflow: hidden;
      pointer-events: none;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $primary;
        z-index: 3;
      }
      &:before {
        content: '';
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
        animation: loading-pulse 1s ease infinite;
      }
    }

    &--danger:after {
      background: $danger !important;
    }
  }

  &--block {
    width: 100%;
  }

  &--tablet {
    &-center {
      @include rwd(tablet) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--small {
    &-block {
      @include rwd(small) {
        display: block;
        width: 100%;
      }
    }
  }

  &--rotate-90 {
    transform: rotate(90deg);
  }
}

.buttons-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -4px;
  &--center {
    justify-content: center;
    &-tablet {
      @include rwd(tablet) {
        justify-content: center;
      }
    }
    &-phone {
      @include rwd(phone) {
        justify-content: center;
      }
    }
  }
  &--block {
    &-small {
      .btn {
        @include rwd(small) {
          display: block;
          width: 80%;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
    }
  }
  &--margin-top {
    &-all {
      margin-top: 20px;
    }
    &-tablet {
      .btn {
        margin-top: 0;
      }
      @include rwd(tablet) {
        margin-top: 15px !important;
      }
    }
  }
  .btn {
    margin: 4px;
  }
  &--more-space {
    margin: 0 -10px;
    .btn {
      margin: 10px;
    }
  }
}
